import React, {useEffect, useState} from 'react'
import i18n from "i18next";
import {useTranslation} from 'react-i18next'
import Navigation from '../../components/Navigation/Navigation'
import {useAppDispatch, useAppSelector} from '../../hooks/redux'
import {contactsFormFetch, photosByPagination} from '../../store/reducers/ActionCreators'
import Loader from '../../components/Loader/Loader'
import BreadCrumbsComponent from "../../components/BreadCrumbsComponent/BreadCrumbsComponent";
import './index.scss'

const Contacts = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const [modalVisible, setModalVisible] = useState(false);
    const [sliderItems, setSliderItems] = useState<any>([])
    const [loader, setLoader] = useState(false)
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const {photos, error, isLoading, selectedNavPage} = useAppSelector(
        state => state.photoReducer
    )
    const [errorModalVisible, setErrorModalVisible] = useState(false);

    const {burgerMenu, showFilter} = useAppSelector(
        state => state.burgerMenuReducer
    )
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e: any) => {
	    setFormData({ ...formData, [e.target.name]: e.target.value });
    };

const handleSubmit = async (e: any) => {
	e.preventDefault();
    setLoader(true)
    dispatch(contactsFormFetch(formData)).then(
        (res: any) => {
            if (res.payload.status === 200) {
                setSuccessModalVisible(true)
            } else {
                setErrorModalVisible(true)
            }
        })
    setModalVisible(false);
    setLoader(false)
};

    useEffect(() => {
        dispatch(photosByPagination([], [], '', 1))
    }, [])

    const openModal = () => {
        setModalVisible(true);
      };

      const closeModal = () => {
        setModalVisible(false);
      };

      const openSuccessModal = () => {
        setModalVisible(false);
        setSuccessModalVisible(true);
        setFormData({name: "", email: "", message: ""})
      };

      const closeSuccessModal = () => {
        setSuccessModalVisible(false)
        setErrorModalVisible(false);
        setFormData({name: "", email: "", message: ""})
      };

      const handleFormSubmit = (e: any) => {
        e.preventDefault();
        openSuccessModal();
      };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Escape') {
            closeModal();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        let ourProjects = [
            ...photos
                ?.map(item => item?.attributes?.photos)
                .map(p => p.map(i => i.photo?.data?.attributes?.url))
        ]
        let sliderItems = [].concat.apply(
            [],
            ourProjects.map((i: any) => i)
        )
        setSliderItems(sliderItems)
    }, [photos])

    return (
        <>
            <Navigation/>

            {isLoading || loader ? (
                <Loader/>
            ) : (
                !burgerMenu &&
                !showFilter && (
                    <div className='contacts'>
                        <BreadCrumbsComponent
                            titleHome={`${t("Home").toUpperCase()}`}
                            titleOne={`${t("Contacts").toUpperCase()}`}
                            linkHome={`/${i18n.language}/`}
                        />
                        <h2 className='contacts__title'>{t('Our contacts')}</h2>
                        <ul className='contacts__list'>
                            <li>
                                {t('Telephone: ')}
                                <a href='tel:(+994) 55 220 65 65'>(+994) 55 220 65 65</a>
                            </li>
                            <li>
                                Whatsapp:{' '}
                                <a href='tel:(+994) 55 220 65 65'>(+994) 55 220 65 65</a>
                            </li>
                            <li>
                                {t('E-mail: ')}
                                <a href='mailto:info@riberry.az'>info@riberry.az</a>
                            </li>

                            <li>
                                {t('Address: ')}
                                {t('56 Ashig Molla Juma, Baku, Miner Business Center 2nd floor')}
                            </li>
                            <li>
                                <button
                                    onClick={openModal}
                                    className="contacts__btn-write">{t("Write to us and we will answer you")}</button>
                            </li>
                            {modalVisible && (
                                <div className="modal-overlay">
                                    <div className="modal">
                                        <form onSubmit={handleSubmit}>
                                            <div className="modal-wrapper">
                                                <div
                                                    className="modal-wrapper__head">{t("Write to us or leave your contact details")}</div>
                                                <div className="modal-wrapper__body">
                                                    <div className="modal-wrapper__body-up">
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            placeholder={`${t("Name")}`}
                                                            onChange={handleChange}
                                                        />
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            placeholder={`${t("Phone or email")}`}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="modal-wrapper__body-down">
                                                        <textarea
                                                            name="message"
                                                            placeholder={`${t("Enter text")}`}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button className="modal-footer__cancel"
                                                        onClick={closeModal}>{t("Cancel")}</button>
                                                <button type="submit"
                                                        className="modal-footer__send"
                                                        >{t("Send")}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                            {successModalVisible && (
                                <div className="success-modal-overlay">
                                    <div className='success-modal-overlay__close-head'>
                                        <img
                                         className='success-modal-overlay__close-head-img'
                                         src={require('../../assets/close_btn.svg').default}
                                         onClick={closeSuccessModal}
                                         />
                                    </div>
                                    <div className='success-modal-overlay__text-wrap'>
                                        <div>{t("Your application is accepted")}</div>
                                        <div>{t("We will contact you shortly")}</div>
                                    </div>
                                </div>
                            )}
                            {errorModalVisible && (
                                <div className="error-modal-overlay">
                                    <div className='success-modal-overlay__close-head'>
                                        <img
                                            className='success-modal-overlay__close-head-img'
                                            src={require('../../assets/close_btn.svg').default}
                                            onClick={closeSuccessModal}
                                        />
                                    </div>
                                    <div className='error-modal-overlay__text-wrap'>
                                        <div style={{ color: "red", background: "white"}}>{t("Something went wrong")}</div>
                                    </div>
                                </div>
                            )}
                        </ul>
                    </div>
                )
            )}
        </>
    )
}

export default Contacts
