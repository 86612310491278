import React from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    openFilterMenu,
    openSelectedFilter,
    clearAllTypesAction,
    clearAllStylesAction
} from "../../store/reducers/ActionCreators";
import "./index.scss"

interface FilterComponentProps {
    setShowHeader: any
}

const FilterComponent = ({setShowHeader}: FilterComponentProps) => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const {selectedStyle, selectedType} = useAppSelector(
        state => state.filterReducer
    )

    const clearSelectedType = () => {
        dispatch(clearAllTypesAction())
        dispatch(clearAllStylesAction())
        dispatch(openFilterMenu(true))
    };

    const navigateFilter = () => {
        setShowHeader(true);
        dispatch(openSelectedFilter(false));
        dispatch(openFilterMenu(false))
        dispatch(clearAllTypesAction())
        dispatch(clearAllStylesAction())
        navigate("/")
    }


    return (
        <div className="wrapper-filter">
            <NavLink to={`/${i18n.language}/`} className="wrap-filter">
                <div style={{display: "flex"}}
                     onClick={navigateFilter}>
                    <img className="wrap-filter-img" src={require("../../assets/arrow-left.svg").default} alt="Photo"/>
                    <div className="wrap-filter-text">{t("Filter")}</div>
                </div>
                {selectedType?.length || selectedStyle?.length ?
                    <div onClick={clearSelectedType}>{t("Clear")}</div> : null}
            </NavLink>
        </div>
    );
};

export default FilterComponent;
