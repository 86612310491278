import React, { useEffect } from 'react'
import { IStage } from '../../models/IStages'
import PriceInfoStage from '../../components/PriceInfoStage'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import {
	fetchArchitecturePrice,
	fetchArchStages
} from '../../store/reducers/ActionCreators'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader/Loader'
import { IPrice } from '../../models/IPrice'
import "./index.scss"

const Architecture = () => {
	const { stages, isLoading } = useAppSelector(
		state => state.stageReducer
	)
	const { architecturePrices } = useAppSelector(state => state.priceReducer)
	const dispatch = useAppDispatch()
	const { t } = useTranslation()
	useEffect(() => {
		dispatch(fetchArchStages())
		dispatch(fetchArchitecturePrice())
	}, [])
	// const priceBlocks = [
	// 	{ id: 1, title: t('Planning'), price: 12, moreInfo: false },
	// 	{ id: 2, title: t('3D Visualization)'), price: 18, moreInfo: false },
	// 	{
	// 		id: 3,
	// 		title: t('Design project'),
	// 		price: 35,
	// 		moreInfo: false
	// 	},
	// 	{
	// 		id: 4,
	// 		title: t('Landscape design (1 m²)'),
	// 		price: 150,
	// 		moreInfo: false
	// 	}
	// ]
	const clonedPrice: IPrice[] = structuredClone(architecturePrices)

	const sortedPrice = clonedPrice?.sort((a, b) => a.id - b.id)
	return isLoading ? (
			<div className="loader-price">
				<Loader />
			</div>
	) : (
		<div>
			<div className='outlet-wrap'>
				{sortedPrice?.map((p, id) => (
					<div className='outlet-wrap__wrapper' key={id}>
						<div className='outlet-wrap__wrapper-item'>
							{t(p.attributes.title) + ' (1 ' + t(p.attributes.title === "Landscape design " ? "weave" : "m") + ')'}
						</div>
						<div className='outlet-wrap__wrapper-item-con'>
							{p.attributes.price}{' '}
							<span className='outlet-wrap__wrapper-attributes-price'>
								&#8380;{' '}
							</span>
						</div>
					</div>
				))}
			</div>
			<h2 className="outlet-head-text">
				{t('A complete architectural project includes the following steps:')}
			</h2>
			{/*<div className='price-info-wrapper'>*/}
			{/*	/!*{priceBlocks.map(priceItem => (*!/*/}
			{/*	/!*	<PriceInfoBlock*!/*/}
			{/*	/!*		key={priceItem.id}*!/*/}
			{/*	/!*		title={priceItem.title}*!/*/}
			{/*	/!*		price={priceItem.price}*!/*/}
			{/*	/!*		moreInfo={priceItem.moreInfo}*!/*/}
			{/*	/!*	/>*!/*/}
			{/*	/!*))}*!/*/}
			{/*</div>*/}
			<div className='stages-wrapper'>
				{stages.map((stage: IStage) => (
					<PriceInfoStage key={stage.id} stage={stage} />
				))}
			</div>
		</div>
	)
}

export default Architecture
