export const interiorPlaceType = [
	{ id: 1, value: 'Living room' },
	{ id: 2, value: 'Kitchen' },
	{ id: 3, value: 'Bedroom' },
	{ id: 22, value: 'Bathroom' },
	{ id: 4, value: "Boy's room" },
	{ id: 5, value: "Girl's room" },
	{ id: 6, value: 'Restroom' },
	{ id: 7, value: 'Hall' },
	{ id: 8, value: "Gender-neutral children's room" },
	{ id: 9, value: 'Wardrobe' },
	{ id: 10, value: 'Studio room' },
	{ id: 11, value: 'Pantry' },
	{ id: 12, value: 'Storehouse' },
	{ id: 13, value: 'Cabinet' },
	{ id: 14, value: 'Balcony' },
	{ id: 15, value: 'Mansard' },
	{ id: 16, value: 'Private xamam' },
	{ id: 17, value: 'Stairs' },
	{ id: 18, value: 'Dining room' },
	{ id: 19, value: 'Playing room' },
	{ id: 20, value: 'Library' },
	{ id: 21, value: 'Gym' },
	{ id: 23, value: 'Laundry' },
	{ id: 24, value: 'Garage' },
	{ id: 25, value: 'Alcove' }
]

export const interiorPlaceStyle = [
	{ id: 1, value: 'Classic' },
	{ id: 2, value: 'Loft' },
	{ id: 3, value: 'Art-Deco' },
	{ id: 4, value: 'Vanguard' },
	{ id: 5, value: 'Rococo' },
	{ id: 6, value: 'Chalet' },
	{ id: 7, value: 'French country' },
	{ id: 8, value: 'French classics' },
	{ id: 9, value: 'Fusion' },
	{ id: 10, value: 'Contemporary' },
	{ id: 11, value: 'Neo-baroque' },
	{ id: 12, value: 'Eco style' },
	{ id: 13, value: 'Art deco' },
	{ id: 14, value: 'Mediterranean', title: 'Mediterranean' },
	{ id: 15, value: 'High-Tech', title: 'High-Tech' },
	{ id: 16, value: 'Country', title: 'Country' },
	{ id: 17, value: 'Eclecticism', title: 'Eclecticism' },
	{ id: 18, value: 'Eco', title: 'Eco' },
	{ id: 19, value: 'Oriental style', title: 'Oriental style' },
	{ id: 20, value: 'French Mediterranean', title: 'French Mediterranean' },
	{ id: 21, value: 'Provence', title: 'Provence' },
	{ id: 22, value: 'Neoclassic', title: 'Neoclassic' },
	{ id: 23, value: 'American Classic', title: 'American Classic' },
	{ id: 24, value: 'Modern style', title: 'Modern style' },
	{ id: 25, value: 'High tech', title: 'High tech' },
	{ id: 26, value: 'Minimalism', title: 'Minimalism' },
	{ id: 27, value: 'English style', title: 'English style' },
	{ id: 28, value: 'Baroque', title: 'Baroque' },
	{ id: 29, value: 'Modern', title: 'Modern' },
	{ id: 30, value: 'Scandinavian style', title: 'Scandinavian style' },
	{ id: 31, value: 'Constructivism', title: 'Constructivism'},
	{ id: 32, value: 'Ethnic style', title: 'Ethnic style'},
	{ id: 33, value: 'Mediterranean style', title: 'Mediterranean style'},
	{ id: 34, value: 'Parisian style', title: 'Parisian style'},
	{ id: 35, value: 'English style', title: 'English style'},
	{ id: 36, value: 'German style', title: 'German style'},
	{ id: 37, value: 'French style', title: 'French style'},
	{ id: 38, value: 'Bionic architecture', title: 'Bionic architecture'},
	{ id: 39, value: 'Japandi', title: 'Japandi'},
	{ id: 40, value: 'Baku style', title: 'Baku style'},
	{ id: 41, value: 'Luxury modern', title: 'Luxury modern'},
]


export const exteriorPlaceStyle = [
	{ id: 1, value: 'Classic' },
	{ id: 2, value: 'Art deco' },
	{ id: 3, value: 'Eclecticism', title: 'Eclecticism'},
	{ id: 4, value: 'Oriental style', title: 'Oriental style'},
	{ id: 5, value: 'Neoclassic', title: 'Neoclassic'},
	{ id: 6, value: 'Modern style', title: 'Modern style'},
	{ id: 7, value: 'Minimalism', title: 'Minimalism'},
	{ id: 8, value: 'Modern', title: 'Modern'},
	{ id: 9, value: 'Scandinavian style', title: 'Scandinavian style'},
	{ id: 10, value: 'Constructivism', title: 'Constructivism'},
	{ id: 11, value: 'Ethnic style', title: 'Ethnic style'},
	{ id: 12, value: 'Mediterranean style', title: 'Mediterranean style'},
	{ id: 13, value: 'Parisian style', title: 'Parisian style'},
	{ id: 14, value: 'English style', title: 'English style'},
	{ id: 15, value: 'German style', title: 'German style'},
	{ id: 16, value: 'French style', title: 'French style'},
	{ id: 17, value: 'Bionic architecture', title: 'Bionic architecture'},

]

export const publicPlaceType = [
	{ id: 1, value: 'Hamam' },
	{ id: 2, value: 'Beauty salon' },
	{ id: 3, value: 'Kindergarden' },
	{ id: 4, value: 'Showroom' },
	{ id: 5, value: 'Fitness centre' },
	{ id: 6, value: 'Medical centre' },
	{ id: 7, value: 'Shop' },
	{ id: 8, value: 'Office' },
	{ id: 9, value: 'Bar' },
	{ id: 10, value: 'Restaurant' },
	{ id: 11, value: 'Pool' },
	{ id: 12, value: 'Sauna' },
	{ id: 13, value: 'Hotel' },
	{ id: 14, value: 'Transitions' },
]

export const landscapePlaceType = [
	{ id: 1, value: 'Small architectural forms/buildings' },
	{ id: 2, value: 'Public buildings' },
	{ id: 3, value: 'Landscape design' },
	{ id: 4, value: 'Private residential buildings' },
]

export const realizationPlaceType = [
	{ id: 21, value: 'Living room' },
	{ id: 22, value: 'Kitchen' },
	{ id: 23, value: 'Bedroom' },
	{ id: 24, value: 'Bathroom' },
	{ id: 25, value: "Boy's room" },
	{ id: 26, value: "Girl's room" },
	{ id: 27, value: 'Restroom' },
	{ id: 28, value: 'Hall' },
	{ id: 29, value: "Gender-neutral children's room" },
	{ id: 30, value: 'Wardrobe' },
	{ id: 31, value: 'Studio room' },
	{ id: 32, value: 'Pantry' },
	{ id: 33, value: 'Storehouse' },
	{ id: 34, value: 'Cabinet' },
	{ id: 35, value: 'Balcony' },
	{ id: 36, value: 'Mansard' },
	{ id: 37, value: 'Private xamam' },
	{ id: 38, value: 'Stairs' },
	{ id: 39, value: 'Dining room' },
	{ id: 40, value: 'Playing room' },
	{ id: 41, value: 'Library' },
	{ id: 42, value: 'Gym' },
	{ id: 43, value: 'Laundry' },
	{ id: 44, value: 'Garage' },
	{ id: 2, value: 'Private interior' },
	{ id: 3, value: 'Kindergarden', title: 'Kindergarden' },
	{ id: 4, value: 'Hamam' },
	{ id: 5, value: 'Showroom', title: 'Showroom' },
	{ id: 6, value: 'Fitness centre' },
	{ id: 7, value: 'Medical centre' },
	{ id: 8, value: 'Office' },
	{ id: 9, value: 'Bar', title: 'Bar' },
	{ id: 10, value: 'Restaurant' },
	{ id: 11, value: 'Public buildings' },
	{ id: 12, value: 'Shop'},
	{ id: 13, value: 'Landscape design' },
	{ id: 14, value: 'Beauty salon' },
	{ id: 16, value: 'Pool' },
	{ id: 17, value: 'Sauna' },
	{ id: 18, value: 'Hotel' },
	{ id: 19, value: 'Transitions' },
	{ id: 45, value: 'Private residential buildings' },
	{ id: 46, value: 'Small architectural forms/buildings' },
	{ id: 47, value: 'Alcove' },
]
export enum Language {
	ru = 'ru',
	az = 'az',
	en = 'en'
}
