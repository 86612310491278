import React from 'react'
import Slider from 'react-slick'
import './index.scss'
import {CONFIG} from '../../config'
import {NavLink} from "react-router-dom";

const baseUrl = 'http://riberry.az/api'

const SliderComponent = ({sliderItems}: any) => {
    const settings = {
        className: 'center',
        infinite: true,
        centerPadding: '60px',
        speed: 4000,
        slidesToShow: 6,
        slidesToScroll: 4,
        initialSlide: 0,
        autoplay: true,
        // autoplay: false,
        autoplaySpeed: 500,
        swipeToSlide: true,
        useCSS: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 4,
                    initialSlide: 4
                }
            },
            {
                breakpoint: 1220,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 4
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 4
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <NavLink to='/'>
            <Slider {...settings}>
                {sliderItems.map((item: string) => (
                    <img
                        className='picture-slide'
                        style={{margin: '0 20px'}}
                        src={CONFIG.photoBaseUrl + item}
                        alt='Photo'
                    />
                ))}
            </Slider>
        </NavLink>
    )
}

export default SliderComponent
