import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import './index.scss'
import {IStage} from '../../models/IStages'
import {useTranslation} from 'react-i18next'
import {useAppSelector} from '../../hooks/redux'
import {CONFIG} from '../../config'
import ReactMarkdown from "react-markdown";
import {main} from "../../parser";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {consumers} from "node:stream";

interface PriceInfoStageProps {
    stage: IStage
}

const PriceInfoStage = ({stage}: PriceInfoStageProps) => {
    const [showFullText, setShowFullText] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [hiddenText, setHiddenText] = useState<boolean>(true);
    const [displayText, setDisplayText] = useState<string>("");
    const [displayCutText, setDisplayCutText] = useState<string>("");
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [textHeight, setTextHeight] = useState<number>(0);
    const [imageHeight, setImageHeight] = useState<number>(0);
    const textRef = useRef<HTMLDivElement>(null);
    const imgRef = useRef<HTMLDivElement>(null);
    const {t} = useTranslation()
    const {lang} = useAppSelector(state => state.settingsReducer)
    const fullText = stage.attributes.stage_desc?.[lang] || "";
    const leftBlockRef = useRef(null);
    const { width } = useWindowDimensions();

    const cutTextLength = width >= 3000 ? 1700 : 957;
    const cutText = fullText.slice(0, cutTextLength);

    useEffect(() => {
        setDisplayText(fullText);
        setDisplayCutText(cutText)
    }, [lang, fullText]);

    useLayoutEffect(() => {
        if (textRef.current) {
            setTextHeight(textRef.current.clientHeight);
        }
    }, [fullText, lang, width]);


    const handleToggleText = () => {
        setHiddenText(!hiddenText);
        setShowFullText(!showFullText);
    };

    const showReadMore = fullText.length > 958;

    return (
        <div className='stage'>
            <div className='stage-body'>
                <div ref={leftBlockRef} className='stage-body__left'>
                    <div className='stage-body__wrap'>
                        <div className='stage-number-large'>
                            <div>
                                {t('Stage')} {stage.attributes.stage}
                            </div>
                        </div>
                        <div className='stage-title'>
                            {stage.attributes.stage_title?.[lang]}
                        </div>
                        <div className="stage-text"
                             ref={textRef}
                             style={{
                                 maxHeight: width < 495 ? (showFullText ? 'none' : '490px') : width < 534 ? (showFullText ? 'none' : '309px') : (showFullText ? 'none' : '330px'),
                                 overflow: 'hidden' }}
                        >
                            <ReactMarkdown>{showFullText ? fullText : cutText}</ReactMarkdown>
                        </div>
                        {showReadMore && (
                            <div className="stage-text-more">
                                <div className='stage-text-more__txt' onClick={handleToggleText}>
                                    {showFullText ? t('collapse') : t(' read more')}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='stage-body__right'>
                    {stage.attributes.stage_image.data === null ?
                        <></> :
                        <img className="stage-body__right-image"
                             src={
                                 CONFIG.photoBaseUrl +
                                 (stage.attributes.stage_image?.data?.attributes?.formats?.large?.url ||
                                 stage.attributes.stage_image?.data?.attributes?.url)
                             }
                             alt='Photo'
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default PriceInfoStage
