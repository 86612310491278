import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {IPhoto} from '../../models/IPhoto'
import {useAppDispatch, useAppSelector} from '../../hooks/redux'
import {
    clearAllStylesAction,
    clearAllTypesAction,
    clearPhotoState,
    fetchPhotosByFilter,
    photosByPagination
} from '../../store/reducers/ActionCreators'
import './index.scss'
import Navigation from '../../components/Navigation/Navigation'
import {useTranslation} from 'react-i18next'
import {CONFIG} from '../../config'
import Loader from '../../components/Loader/Loader'
import MainPageItemsLayout from '../../layouts/mainPageItemsLayout'
import Triple from '../../components/BlockForm/Triple'
import {useLocation} from "react-router-dom";

import {main} from "../../parser";
import useInViewPort from "../../hooks/useInViewPort";

const Home = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const [selectedNav, setSelectedNav] = useState(1)
    const [showAllTypeFilters, setShowAllTypeFilters] = useState(false)
    const [showAllStyleFilters, setShowAllStyleFilters] = useState(false)
    const [showTypes, setShowTypes] = useState<boolean>(false)
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showStyles, setShowStyles] = useState<boolean>(false)
    const {photos, rightPhotos, isLoading, selectedNavPage, pageCount} =
        useAppSelector(state => state.photoReducer)
    const {burgerMenu, showFilter} = useAppSelector(
        state => state.burgerMenuReducer
    )
    const {selectedStyle, selectedType} = useAppSelector(
        state => state.filterReducer
    )
    const [page, setPage] = useState(1)
    const location = useLocation();
    const [pathName, setPathName] = useState('');
    const targetRef = useRef<HTMLDivElement>(null);
    const [isFetching, setIsFetching] = useState(false);

    const handlePageIncrement = () => {
        if (!isFetching && page < pageCount) {
            setIsFetching(true);
            setPage(page =>page + 1);
        }
    };

    useLayoutEffect(() => {
        if(location.pathname !== pathName) {
            setPage(1)
        }
    }, [location.pathname]);


    useInViewPort(targetRef, { threshold: 0.1 }, handlePageIncrement, 200, 100);

    useEffect(() => {
        if (page === 1 && photos.length > 0) {
            dispatch(clearPhotoState());
        }
        setPathName(location.pathname);
        dispatch(photosByPagination(selectedType, selectedStyle, location.pathname, page)).then(() => {
            setDataLoaded(true);
            setIsFetching(false);
        });
    }, [page, selectedStyle, selectedType]);

    useEffect(() => {
        setPage(1);
        setShowAllTypeFilters(false);
        setShowAllStyleFilters(false);
        dispatch(fetchPhotosByFilter(selectedType, selectedStyle, selectedNavPage, page));

        return () => {
            dispatch(clearPhotoState());
        }
    }, [selectedNavPage]);


    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    let uniqueIdsPhoto: any = {};
    let uniqueIdsRightPhoto: any = {};

    const filteredArrayPhotos = photos.filter(item => {
        if (uniqueIdsPhoto.hasOwnProperty(item.id)) {
            return false;
        }
        uniqueIdsPhoto[item.id] = true;
        return true;
    });

    const filteredArrayRightPhotos = rightPhotos.filter(item => {
        if (uniqueIdsRightPhoto.hasOwnProperty(item.id)) {
            return false;
        }
        uniqueIdsRightPhoto[item.id] = true;
        return true;
    });
    // main()
    const oneStep = location.pathname.replace(/^(\/ru\/|\/az\/|\/en\/)/, '').replace(/\/$/, '');
    return (
        <>
            {isLoading || !dataLoaded ?  (
                <Loader/>
            ) : (
                <>
                    <Navigation
                        selectedNav={selectedNav}
                        setSelectedNav={setSelectedNav}
                        setPage={setPage}
                        showAllStyleFilters={showAllStyleFilters}
                        showAllTypeFilters={showAllTypeFilters}
                        setShowAllStyleFilters={setShowAllStyleFilters}
                        setShowAllTypeFilters={setShowAllTypeFilters}
                        showTypes={showTypes}
                        setShowTypes={setShowTypes}
                        showStyles={showStyles}
                        setShowStyles={setShowStyles}
                    />

                    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>

                        {
                            selectedType.length > 0 || selectedStyle.length > 0
                                ?
                                <div style={{display: "flex", paddingBottom: 20}}>
                                    <div style={{color: "#ffddba", cursor: "pointer"}} onClick={() => {
                                        setShowTypes(false)
                                        setShowStyles(false)
                                        dispatch(clearPhotoState())
                                        dispatch(clearAllTypesAction())
                                        dispatch(clearAllStylesAction())
                                    }}>{`${t(oneStep)}`}</div>
                                    {selectedType.length > 0 ? <div style={{
                                        color: "#ffddba",
                                        paddingLeft: 5,
                                        paddingRight: 5
                                    }}>{" > "}</div> : undefined}
                                    <div style={{color: "#ffddba", cursor: "pointer"}} onClick={() => {
                                        dispatch(clearAllStylesAction())
                                        dispatch(clearPhotoState())
                                        setShowTypes(false)
                                    }}>{t(selectedType[0])}</div>
                                    {selectedStyle.length > 0
                                        ?
                                        <div style={{display: "flex"}}>
                                            <div style={{
                                                color: "#ffddba",
                                                paddingLeft: 5,
                                                paddingRight: 5
                                            }}>{" > "}</div>
                                            <div style={{color: "#ffddba"}}>{t(selectedStyle[0])}</div>
                                        </div>
                                        :
                                        ''
                                    }
                                </div>
                                :
                                ""
                        }

                        <div
                            className={!showFilter && !burgerMenu && photos?.length ? 'columns-wrapper' : 'colums-wrapper-noPhoto'}
                            style={{
                                display: showFilter || burgerMenu ? 'none' : 'flex',
                            }}
                        >


                            <div className='columns'>
                                {!showFilter && !burgerMenu && photos?.length ? (
                                    <>
                                        <div className='left-column'>
                                            {filteredArrayPhotos?.map((item: any, i: number) => {
                                                return (
                                                    <MainPageItemsLayout key={i} item={item} className='left-block'>
                                                        <Triple item={item} baseUrl={CONFIG.photoBaseUrl}/>
                                                    </MainPageItemsLayout>
                                                )
                                            })}
                                        </div>
                                        {filteredArrayRightPhotos?.length ? (
                                            <div className='right-column'>
                                                {filteredArrayRightPhotos?.map((item: IPhoto, i: number) => {
                                                    return (
                                                        <MainPageItemsLayout
                                                            item={item}
                                                            className='right-block'
                                                            key={i}
                                                        >
                                                            <Triple
                                                                reverse
                                                                item={item}
                                                                baseUrl={CONFIG.photoBaseUrl}
                                                            />
                                                        </MainPageItemsLayout>
                                                    )
                                                })}
                                            </div>
                                        ) : null}
                                    </>
                                ) : (
                                    <div className="columns-error">
                                        {t('Sorry, nothing was found for your request')}
                                    </div>
                                )}
                            </div>
                            <div ref={targetRef} style={{height: '1px'}}></div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Home
