import React, {useEffect, useState} from 'react';

interface ScrollData {
    isVisible: boolean;
    showButton: boolean;
}
const FooterBtnUp: React.FC = () => {
    const [scrollData, setScrollData] = useState<ScrollData>({
        isVisible: false,
        showButton: true
    });

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const lastOffset = document.documentElement.scrollHeight - window.innerHeight - 200;

            setScrollData({
                isVisible: scrollY > 500,
                showButton: scrollY < lastOffset
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const bottomValue = window.innerWidth > 1024 ? (scrollData.showButton ? 20 : 70) : 20;

    return (
        <img
            src={require("../../assets/upArrow.svg").default}
            className={`scroll-to-top-button ${scrollData.isVisible ? 'visible' : ''}`}
            style={{ bottom: bottomValue }}
            onClick={scrollToTop}
        />
    );
};

export default React.memo(FooterBtnUp);
