import React from 'react'
import {NavLink, useLocation, useNavigate} from 'react-router-dom'
import { useAppSelector } from '../hooks/redux'
import InfoBlock from '../components/InfoBlock'
import {useTranslation} from "react-i18next";

interface Props {
	item: any
	children: any
	className: string
}

const MainPageItemsLayout = ({ item, children, className }: Props) => {
	const { burgerMenu, showFilter } = useAppSelector(
		state => state.burgerMenuReducer
	)
	return (
		<div key={item?.id} className={className}>
			<NavLink className='img-wrapper' to={`moreInfo/${item?.id}`}>
				{children}
				{!burgerMenu && !showFilter && <InfoBlock item={item} />}
			</NavLink>
		</div>
	)
}

export default MainPageItemsLayout
