import React, {useEffect, useMemo, useState} from 'react'
import PriceInfoBlock from '../../components/PriceInfoBlock'
import './index.scss'
import PriceInfoStage from '../../components/PriceInfoStage'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import {
	fetchDesignPrice,
	fetchServicePricesStages
} from '../../store/reducers/ActionCreators'
import { IStage } from '../../models/IStages'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader/Loader'
import { IPrice } from '../../models/IPrice'

const InteriorDesign = () => {
	const dispatch = useAppDispatch()
	const { t } = useTranslation()
	const [stag, setStag] = useState([]);

	const { stages, error, isLoading } = useAppSelector(
		state => state.stageReducer
	)
	const { designPrices } = useAppSelector(state => state.priceReducer)
	useEffect(() => {
		dispatch(fetchServicePricesStages())
		dispatch(fetchDesignPrice())
	}, [])

	const clonedPrice: IPrice[] = structuredClone(designPrices)
	const sortedPrice = clonedPrice?.sort((a, b) => a.id - b.id)?.slice(0, 4)
	const fullDesignPrice = designPrices?.find(p => p.id === 5)

	const copyStages = stages.slice();
	const sortStages = copyStages.sort((a,b) => {
		return parseInt(a.attributes.stage) - parseInt(b.attributes.stage)
	})

	return isLoading ? (
		<div className="loader-price">
			<Loader />
		</div>
	) : (
		<>
			<div className='price-list'>
				<div className='price-list__general'>
					<div className='price-list__general__title'>
						{t('FULL DESIGN PROJECT (1 m²)')}
					</div>
					<div className='price-list__general__items'>
						{sortedPrice?.map((p, index) => (
							<div key={p.id} className='price-list__general__item'>
								<div className='price-list__general__item-title'>
									{t(p.attributes.title === "CompleteDesign300" ? "from " : '' ) + t(p.attributes.title) + ' (1 ' + t("m") + ')'}
								</div>
								<div
									// style={{
									// 	borderRadius:
									// 		p.id === 1 ? '0 0 0 5px' : p.id === 4 ? '0 0 5px 0' : '0',
									// }}
									className='price-list__general__item-price'
								>
									{t(p.attributes.price)}
									{!isNaN(+p.attributes.price) ? (
										<span className='price-list_general-attributes-price' >
											&#8380;{' '}
										</span>
									) : null}
								</div>
							</div>
						))}
					</div>
				</div>
				<div className='price-list__vip'>
					<div className='price-list__vip__title'>
						{t('FULL DESIGN PROJECT (1 m²), VIP package')}
					</div>
					<div className='price-list__vip__title-item'>
						<div className='price-list__vip__item'>
							<div className="price-list__vip-price-metr">{"1 м²"}</div>
							<div className='price-list__vip__item-price'>
								{fullDesignPrice?.attributes?.price}
								{!isNaN(+(fullDesignPrice?.attributes?.price || 0)) ? (
									<span className='price-list_general-attributes-price'>
										&#8380;{' '}
									</span>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
			<h2 className="head-name">
				{t('A complete design project includes the following steps:')}
			</h2>
			{/*<div className='price-info-wrapper'>*/}

			{/*{priceBlocksValue.map(priceItem => (*/}
			{/*	<PriceInfoBlock*/}
			{/*		id={priceItem.id}*/}
			{/*		key={priceItem.id}*/}
			{/*		to100={priceItem.to100}*/}
			{/*		from200={priceItem.from200}*/}
			{/*		from100to200={priceItem.from100to200}*/}
			{/*		title={priceItem.title}*/}
			{/*		price={priceItem.price}*/}
			{/*		moreInfo={priceItem.moreInfo}*/}
			{/*	/>*/}
			{/*))}*/}
			{/*</div>*/}
			<div className='stages-wrapper'>
				{sortStages?.map((stage: IStage) => (
					<PriceInfoStage key={stage.id} stage={stage} />
				))}
			</div>
		</>
	)
}

export default InteriorDesign
