import React, {useEffect, useState} from 'react';
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import Navigation from "../Navigation/Navigation";
import Loader from "../Loader/Loader";
import "./index.scss";
import {useDispatch} from "react-redux";
import {fetchQuestionsAnswers} from "../../store/reducers/ActionCreators";
import {useAppSelector} from "../../hooks/redux";
import BreadCrumbsComponent from "../BreadCrumbsComponent/BreadCrumbsComponent";

const QuestionComponent = () => {
    const dispatch = useDispatch()
    const {burgerMenu, showFilter} = useAppSelector(
        state => state.burgerMenuReducer
    )
    const {t} = useTranslation('translation', {i18n});
    const {answersQuestions, isLoading} = useAppSelector(state => state.answersQuestionReducer)
    const {lang} = useAppSelector(state => state.settingsReducer)
    useEffect(() => {
        // @ts-ignore
        dispatch(fetchQuestionsAnswers())
    }, []);

    return (
        <>
            <Navigation/>
            {isLoading ? (
                <Loader/>
            ) : (!burgerMenu &&
                !showFilter &&
                (
                    <div className='question-wrapper'>
                        <BreadCrumbsComponent
                            titleHome={`${t("Home").toUpperCase()}`}
                            titleOne={`${t("Question").toUpperCase()}`}
                            linkHome={`/${i18n.language}/`}
                        />
                        <div
                            className="question-wrapper__head">{answersQuestions[0]?.attributes.stage_head?.[lang]}</div>
                        {
                            answersQuestions.map((item, id) => {
                                return (
                                    <div key={id} className="question-wrapper__body">
                                        <div
                                            className="question-wrapper__body-quest">{item.attributes.stage_title?.[lang]}</div>
                                        <div
                                            className="question-wrapper__body-info">{item.attributes.stage_desc?.[lang]}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )

            )}
        </>
    );
};

export default QuestionComponent;
