export const CONFIG = {
	baseUrl: '/api',
	photoBaseUrl: ''
}

// @ts-ignore
// export const CONFIG = {
// 	baseUrl: 'https://riberry.az/api',
// 	photoBaseUrl: 'https://riberry.az'
// }

// export const CONFIG = {
// 	// baseUrl: 'http://localhost:1337/admin/api',
// 	baseUrl: 'http://localhost:1337/api',
// 	photoBaseUrl: 'http://localhost:1337'
// }
