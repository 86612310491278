import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchInfoAboutUs, fetchPartners} from "./ActionCreators";
import {IAboutUs} from "../../models/IAboutUs";
import {IPartners} from "../../models/IPartners";

interface PartnersSlice {
   	isLoading: boolean
    error: string
    partners:IPartners[] | null
}

const initialState:PartnersSlice = {
    isLoading: false,
    error: '',
    partners: null
}
export const partnersSlice = createSlice({
    name: 'partners',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchPartners.fulfilled.type]: (state, action: PayloadAction<IPartners[]>) => {
            state.isLoading = false
            state.error = ''
            state.partners = action.payload
        },
        [fetchPartners.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchPartners.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        }
    }
})
export default partnersSlice.reducer