import React from 'react'
import './Loader.scss'
import {useTranslation} from 'react-i18next'

const Loader = () => {
    const {t} = useTranslation()
    return <div className='loader'>
        <img  className="wrap" src={require("../../assets/loadingIcon.svg").default} alt="loader"/>
    </div>
}

export default Loader
