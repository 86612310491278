import React from "react";
import {NavLink} from "react-router-dom";
import './index.scss'

interface BreadCrumbsComponentProps {
    link?: string,
    linkHome?: string,
    titleOne?: string,
    titleTwo?: string,
    titleHome?: string
    titleThree?: string,
    arrowRight?: string
    arrowRightOne?: string

}
const BreadCrumbsComponent = ({titleOne,titleTwo, titleThree, link, titleHome, linkHome, arrowRight, arrowRightOne }: BreadCrumbsComponentProps) => {

    return (
        <div className="breadcrumbs-wrapper">
            <NavLink
                className="back-blog"
                // @ts-ignore
                to={linkHome}>{titleHome}</NavLink>
            <span>{" > "}</span>
            <div>{titleOne}</div>
            <span>{arrowRightOne}</span>
            <NavLink className="back-blog"
                // @ts-ignore
                     to={link}>{titleTwo}</NavLink>
            <span>{arrowRight}</span>
            <div
                className="breadcrumbs-wrapper__title">{titleThree}</div>
        </div>
    )
}

export default BreadCrumbsComponent;
