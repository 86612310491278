import React, {useEffect, useState} from 'react'
import Select, {StylesConfig} from 'react-select'
import {NavLink, useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useAppDispatch, useAppSelector} from '../../hooks/redux'
import {
    changeLangAction, clearAllStylesAction, clearAllTypesAction, clearPhotoState,
    openBurgerMenu,
    openFilterMenu,
    openSelectedFilter,
    selectNavPage
} from '../../store/reducers/ActionCreators'
import SwipeToSlide from '../../utils/swipeSlider'

import './Header.scss'


const logo = require('../../assets/logo.svg').default
const filter = require('../../assets/filter.svg').default
const languages = [
    {id: 1, value: 'ru', label: 'Ру'},
    {id: 2, value: 'en', label: 'En'},
    {id: 3, value: 'az', label: 'Az'}
]

interface HeaderProps {
    setShowHeader?: any
}

const Header = ({setShowHeader}: HeaderProps) => {
    const location = useLocation()
    const {t, i18n} = useTranslation()
    const [isActive, setIsActive] = useState(0)
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    const [selectedLanguage, setSelectedLanguage] = useState(
        languages.find(lang => lang.value === window.location.pathname.split('/')[1])?.value || i18n.language
    );
    const dispatch = useAppDispatch()
    const {burgerMenu, showFilter, showSelectedFilter} = useAppSelector(
        state => state.burgerMenuReducer
    )
    const {selectedStyle, selectedType} = useAppSelector(
        state => state.filterReducer
    )
    const langPrefix = location.pathname.startsWith(`/${i18n.language}`) ? `/${i18n.language}` : '';

    const headerLinks = [
        {id: 1, title: t('Interiors'), path: `/${i18n.language}/`},
        {id: 2, title: t('Public places'), path: `${langPrefix}/public/`},
        {id: 3, title: t('Exterior, landscape'), path: `${langPrefix}/landscape/`},
        {id: 4, title: t('Realization'), path: `${langPrefix}/realization/`}
    ]
    useEffect(() => {
        setLanguage(selectedLanguage)
    }, [])
    const setLanguage = (lang: any) => {
        dispatch(changeLangAction(lang))
        i18n.changeLanguage(lang, (err, t) => {
            if (err) return console.log('something went wrong loading', err)
            t('key') // -> same as i18next.t
        })
    }

    useEffect(() => {
        const pathnameParts = window.location.pathname.split('/');
        const detectedLanguage = pathnameParts[1];
        if (!languages.find(lang => lang.value === detectedLanguage)) {
            setSelectedLanguage(i18n.language);
            i18n.changeLanguage(i18n.language);
        } else {
            setSelectedLanguage(detectedLanguage);
            i18n.changeLanguage(detectedLanguage);
        }
    }, [i18n]);

    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
    }, [i18n, selectedLanguage]);

    const handleSelectChange = (value: any) => {
        const newLanguage = value;
        setSelectedLanguage(newLanguage);

        const currentPathname = window.location.pathname;
        const newPathname = currentPathname.replace(`/${i18n.language}`, `/${newLanguage}`);
        window.history.pushState(null, '', newPathname);

        localStorage.setItem('lang', newLanguage);

        i18n.changeLanguage(newLanguage);
        setLanguage(newLanguage)

        window.location.href = newPathname;
    };

    const handleBurgerMenu = () => {
        dispatch(openBurgerMenu(!burgerMenu))
    }
    const handleFilterMenu = () => {
        dispatch(openFilterMenu(!showFilter))
        dispatch(openSelectedFilter(true))
        setShowHeader(false)
    }
    const id = location.pathname.split('/')

    const languagesWithoutSelected = languages?.map(lang => (
        <option
            key={lang.id}
            style={{display: lang.value === selectedLanguage ? 'none' : 'block'}}
            value={lang.value}
        >
            {lang.label}
        </option>
    ))

    const colourStyles: StylesConfig = {
        // @ts-ignore
        option: (
            styles,
            {data, isDisabled, isFocused, isSelected, isSearchable}: any
        ) => {
            const color = 'rgba(255, 221, 186, 1)'
            return {
                ...styles,
                data: languages,
                backgroundColor: 'black !important',
                color,
                isSearchable: false,
                cursor: 'pointer',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: color,
                    color: 'black'
                }
            }
        },
        placeholder: styles => ({...styles}),
        // @ts-ignore
        singleValue: (styles, {data}) => ({
            ...styles,
            color: 'rgba(255, 221, 186, 1)'
        })
    }
    const navigate = useNavigate();
    const handleLogoClick = () => {
        dispatch(clearAllTypesAction());
        dispatch(clearAllStylesAction());
        dispatch(clearPhotoState());

        if (window.location.pathname === `/${i18n.language}/blogs`) {
            navigate(`/${i18n.language}`);
        }
    };

    const pathNameLocal = window.location.pathname.replace(`/${i18n.language}`, `/${localStorage.getItem("lang")}`)
    // const pathNameLocal = window.location.pathname.replace(
    //     new RegExp(`^/(${i18n.language}|${localStorage.getItem("lang")})`),
    //     ''
    // );

    const isSelected = (link: any) => {
        if (`${pathNameLocal}` === link.path) {
            return true;
        }

        if (link.path === `/${i18n.language}/interior/` && !pathNameLocal.startsWith(`/${i18n.language}/interior/`)) {
            return true;
        }

        return false;
    };
    return (
        <>
            {location.pathname !== `${i18n.language}/moreInfo/${id[id?.length - 1]}` && (
                <>
                    {headerLinks.find(link => `${pathNameLocal}` === link.path) || (`${pathNameLocal}` === `/${i18n.language}/interior/`) ? (
                        <div className='navigation'>
                            <NavLink to={`/${window.location.pathname.split('/')[1]}/`} className='brand-name' onClick={handleLogoClick}>
                                <img width={198} height={50} src={logo} alt='logo'/>
                            </NavLink>
                            <div className='select-language tablet-sel'>
                                <Select
                                    components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null
                                    }}
                                    onChange={(value: any) => {
                                        handleSelectChange(value.value)
                                    }}
                                    isSearchable={false}
                                    defaultValue={languages?.find(
                                        lang => lang.value === selectedLanguage
                                    )}

                                    className={'cselect'}
                                    options={languages?.filter(
                                        lang => lang.value !== selectedLanguage
                                    )}
                                    styles={colourStyles}
                                />
                            </div>

                            <div onClick={handleFilterMenu} className='mobile-filter-btn'>
                                <img src={filter} alt='filter'/>
                            </div>
                            <div
                                onClick={handleBurgerMenu}
                                className={
                                    burgerMenu ? 'icon-three active-three' : 'icon-three'
                                }
                            >
                                <div className='hamburger  hamburger-three'/>
                            </div>

                            <div
                                className={
                                    isNavExpanded ? 'navigation-menu expanded' : 'navigation-menu'
                                }
                            >
                                <ul>
                                    {headerLinks.map((link, index) => {
                                        return (
                                            <li key={link.id}>
                                                <NavLink
                                                    onClick={() => {
                                                        if (link.path === location.pathname)
                                                            dispatch(selectNavPage(location.pathname))
                                                            setIsActive(link.id)
                                                            dispatch(clearAllTypesAction())
                                                            dispatch(clearAllStylesAction())
                                                        }}
                                                    className={(index === 0 && `${pathNameLocal}` === `/${i18n.language}/interior/`) || (`${pathNameLocal}` === link.path) ? 'selected' : ''}
                                                    to={link.path}
                                                >
                                                    {link.title}
                                                </NavLink>
                                            </li>
                                        )
                                    })}
                                    <Select
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null
                                        }}
                                        onChange={(value: any) => {
                                            handleSelectChange(value.value)
                                        }}
                                        isSearchable={false}
                                        defaultValue={languages?.find(
                                            lang => lang.value === selectedLanguage
                                        )}
                                        className={'cselect'}
                                        options={languages?.filter(
                                            lang => lang.value !== selectedLanguage
                                        )}
                                        styles={colourStyles}
                                    />
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <>
                            {pathNameLocal !== `/moreInfo/${id[id?.length - 1]}` ?
                                (<div className='navigation only-lang'>
                                    <NavLink to={`/${i18n.language}/`} className='brand-name'>
                                        <img width={198} height={50} src={logo} alt='logo'/>
                                    </NavLink>
                                    <div
                                        onClick={() => dispatch(openBurgerMenu(!burgerMenu))}
                                        className={
                                            burgerMenu ? 'icon-three active-three' : 'icon-three'
                                        }
                                    >
                                        <div className='hamburger  hamburger-three'></div>
                                    </div>
                                    <Select
                                        onChange={(value: any) => {
                                            handleSelectChange(value.value);
                                        }}
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null
                                        }}
                                        isSearchable={false}
                                        defaultValue={languages.find(
                                            lang => lang.value === selectedLanguage
                                        )}
                                        className={'cselect'}
                                        options={languages.filter(
                                            lang => lang.value !== selectedLanguage
                                        )}
                                        styles={colourStyles}
                                    />
                                </div>)
                                :
                                    null
                            }
                        </>
                    )}
                    {
                        headerLinks.find(link => pathNameLocal === link.path) &&
                        !burgerMenu &&
                        !showFilter &&
                        !selectedType.length &&
                        !selectedStyle.length && (
                            <div className='header__slider'>
                                <SwipeToSlide
                                    isActive={isActive}
                                    setIsActive={setIsActive}
                                    headerLinks={headerLinks}
                                />
                            </div>
                        )}
                </>
            )}
        </>
    )
}

export default Header
