import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../hooks/redux'
import {fetchBlog, fetchBlogs} from '../../store/reducers/ActionCreators'
import './index.scss'
import Navigation from '../../components/Navigation/Navigation'
import {NavLink} from 'react-router-dom'
import Loader from '../../components/Loader/Loader'
import {useTranslation} from 'react-i18next'
import {CONFIG} from '../../config'
import ReactMarkdown from "react-markdown";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {incrementCountBlog} from "../../store/reducers/BlogsSlice";
import BreadCrumbsComponent from "../../components/BreadCrumbsComponent/BreadCrumbsComponent";
import i18n from "i18next";

const baseUrl = 'http://riberry.az/api'
const play = require('../../assets/play.svg').default

const Blogs = () => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const {burgerMenu} = useAppSelector(state => state.burgerMenuReducer)
    const {lang} = useAppSelector(state => state.settingsReducer)
    const {blogs, isLoading} = useAppSelector(
        state => state.blogsReducer
    )
    const textRef = useRef<HTMLDivElement>(null);
    const [textHeight, setTextHeight] = useState<number>(0);
    const [showBlog, setShowBlog] = useState(false)
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const sortedArr = Object.values(blogs);
    const sortDataById = sortedArr.sort((a,b) => a.id - b.id)
    const { width } = useWindowDimensions();

    useEffect(() => {
        dispatch(fetchBlogs())
    }, [])

    const incrementCount = () => {
        dispatch(incrementCountBlog());
    };

    let maxHeight: string;
    if (width < 320) {
        maxHeight = isExpanded ? 'none' : '58px';
    } else if (width >= 320 && width < 600) {
        maxHeight = isExpanded ? 'none' : '58px';
    } else if (width >= 600 && width < 620) {
        maxHeight = isExpanded ? 'none' : '307px';
    } else if (width >= 620 && width < 860) {
        maxHeight = isExpanded ? 'none' : '343px';
    } else if (width >= 860 && width < 934) {
        maxHeight = isExpanded ? 'none' : '350px';
    } else if (width >= 933 && width < 1024) {
        maxHeight = isExpanded ? 'none' : '370px';
    } else if (width < 1200) {
        maxHeight = isExpanded ? 'none' : '350px';
    } else {
        maxHeight = isExpanded ? 'none' : '370px';
    }

    return (
        <>
            <Navigation/>
            {isLoading ? (
                <Loader/>
            ) : (
                !burgerMenu && (
                    <div className='blogs-wrapper'>
                        {showBlog ? (
                            <div className="show-blogs">{t("sorryBlogs")}</div>
                        ) : (
                            <>
                                <h2 className='blogs-title'>
                                    {sortDataById[0]?.attributes.stage_head?.[lang]}
                                </h2>
                                <BreadCrumbsComponent
                                    titleOne={`${t("Blog").toUpperCase()}`}
                                    titleTwo={`${t('Articles').toUpperCase()}`}
                                    linkHome={`/${i18n.language}/`}
                                    titleHome={`${t("Home").toUpperCase()}`}
                                    arrowRightOne={" > "}
                                />
                                <div className='blogs'>

                                    {sortDataById?.map((item) => (
                                        <div className="blogs-wrap" key={item.id}>
                                            <div className="blogs-wrap_left">
                                                <NavLink to={`${item.id}`}
                                                         className="blogs-wrap_left-title">{item.attributes.stage_title?.[lang]}</NavLink>
                                                <div
                                                    ref={textRef}
                                                    className={`desc ${isExpanded ? 'expanded' : ''}`}
                                                    style={{
                                                        maxHeight: maxHeight,
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    <ReactMarkdown>{(item.attributes.stage_desc?.[lang])}</ReactMarkdown>
                                                </div>
                                                <div onClick={incrementCount} className="blogs-wrap_left-more">
                                                    {item.attributes.stage_desc &&
                                                        <NavLink to={`${item.id}`} style={{
                                                            border: "none",
                                                            outline: "none",
                                                            color: "rgb(255, 221, 186)",
                                                            cursor: "pointer"
                                                        }}>
                                                            {isExpanded ? null : `${t(' read more')}`}
                                                        </NavLink>
                                                    }
                                                </div>
                                            </div>
                                            <div className="blogs-wrap_right">
                                                <img
                                                    className='blogs-wrap_right-image'
                                                    src={
                                                        CONFIG.photoBaseUrl +
                                                        item?.attributes.blog_img?.data[0]?.attributes?.formats?.large?.url
                                                    }
                                                    alt='Photo'
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                )
            )}
        </>
    )
}

export default Blogs
