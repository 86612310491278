import React, {useEffect, useState} from 'react'
import Navigation from '../../components/Navigation/Navigation'
import {fetchBlog, fetchBlogs} from '../../store/reducers/ActionCreators'
import {useAppDispatch, useAppSelector} from '../../hooks/redux'
import {NavLink, useLocation, useParams, Link, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import './index.scss'
import {CONFIG} from '../../config'
import i18n from "i18next";
import moment from 'moment';
import FooterContentBlogInfo from "../../components/FooterContentBlogInfo/FooterContentBlogInfo";
import Loader from "../../components/Loader/Loader";
import BreadCrumbsComponent from "../../components/BreadCrumbsComponent/BreadCrumbsComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar, faStarHalfStroke} from "@fortawesome/free-solid-svg-icons";
//@ts-ignore
import ReactStars from "react-rating-stars-component";

const Blog = () => {
    const { language } = useParams();
    const {t} = useTranslation()
    const location = useLocation()
    const params = useParams()
    const dispatch = useAppDispatch()
    const [rating, setRating] = useState<number>(0);
    const {blog, isLoading} = useAppSelector(state => state.blogReducer)
    const {burgerMenu} = useAppSelector(state => state.burgerMenuReducer)
    const {lang} = useAppSelector(state => state.settingsReducer)
    const {blogs} = useAppSelector(
        state => state.blogsReducer
    )
    const dataBlog = moment(blog.attributes.blog_date).format('DD/MM/YYYY');

    useEffect(() => {
        dispatch(fetchBlog(params.id));
    }, [params.id]);

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    useEffect(() => {
        dispatch(fetchBlogs())
    }, []);

    useEffect(() => {

    }, [location.pathname]);

    const scrollToSection = (sectionId: any) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({behavior: 'smooth'});
        }
    };
    const resultArrContent = blog.attributes.blog_contents;
    const isCategoryArticle = blog.attributes.category_article;
    const equalCategoryArticle = blogs.filter((item) => item.attributes.category_article === isCategoryArticle);

    console.log("equalCategoryArticle", equalCategoryArticle)

    return (
        <>
            <Navigation/>
            {isLoading ? (
                    <Loader/>
                ) : (
                !burgerMenu && (
                    <div className='one-blog'>
                        <div className='top-panel'>
                            <div className='back__btn'>
                            </div>
                        </div>
                        <div className='one-blog__item'>
                            {blog.attributes.type === 'video' ? (
                                <video
                                    className='one-blog__item-video'
                                    // src={
                                    //     CONFIG.photoBaseUrl +
                                    //     blog.attributes?.blog_img?.data[0]?.attributes?.url
                                    // }
                                    controls
                                ></video>
                            ) : (
                                <>
                                    <img
                                        className='rightImg'
                                        src={
                                            CONFIG.photoBaseUrl +
                                            blog.attributes?.blog_img?.data[0]?.attributes.url
                                        }
                                        alt='Photo'
                                    />
                                    <div className='one-blog__description'>
                                        <h1 className='one-blog__title'>
                                            {blog?.attributes.stage_title?.[lang]}
                                        </h1>
                                        <div className="one-blog__bread-crumbs">
                                            <BreadCrumbsComponent
                                                link={`/${i18n.language}/blogs/`}
                                                titleOne={`${t("Blog").toUpperCase()}`}
                                                linkHome={`/${i18n.language}/`}
                                                titleTwo={`${t('Articles').toUpperCase()}`}
                                                titleHome={`${t("Home").toUpperCase()}`}
                                                arrowRight={" > "}
                                                arrowRightOne={" > "}
                                                titleThree={blog?.attributes.stage_title?.[lang]}
                                            />
                                        </div>
                                        <div className="one-blog__blog-short">
                                            {blog.attributes.stage_desc?.[lang]}
                                        </div>
                                        <nav className="one-blog__blog-conent">
                                            <div className="one-blog__blog-content-head">
                                                {t("tableContents")}
                                            </div>
                                            {blog?.attributes.blog_contents?.map((item, id) => {
                                                const blogTitleKey = `blog_title_${lang}`;
                                                return (
                                                    <ul key={id} className="one-blog__blog-content-head-ul">
                                                        <li onClick={() => scrollToSection(item.id)}>{id + 1}. {item[blogTitleKey]}</li>
                                                    </ul>
                                                )
                                            })}
                                        </nav>
                                        <div className="one-blog__blog-conent-body-wrap">
                                            {resultArrContent.map((item, id) => {
                                                const blogTitleKey = `blog_title_${lang}`;
                                                const blogBodyKey = `blog_body_${lang}`;
                                                return (
                                                    <section key={id} style={{margin: "20px 0 10px 0"}}
                                                             id={String(item.id)}>
                                                        <h2>{item[blogTitleKey]}</h2>
                                                        <div style={{paddingBottom: 10}}>{item[blogBodyKey]}</div>
                                                        <div className="content-images-wrapper">
                                                            {[1, 2, 3].map((index) => (
                                                                <div key={index}
                                                                     className="content-images-wrapper__wrap">
                                                                    {
                                                                        item[`blog_contents_image_${index}`]?.data === null ? "" :
                                                                            <img
                                                                                style={{
                                                                                    border: item[`blog_contents_image_${index}`]?.data === null ? "2px solid white" : ""
                                                                                }}
                                                                                className="blog-content-image"
                                                                                src={
                                                                                    CONFIG.photoBaseUrl +
                                                                                    (item[`blog_contents_image_${index}`]?.data
                                                                                        ? item[`blog_contents_image_${index}`].data[0]?.attributes.url
                                                                                        : "")
                                                                                }
                                                                                alt="Photo"
                                                                            />
                                                                    }
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </section>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="recomend-content">
                            <span>{t('recommendedArticles')}</span>
                            <div className="recomend-content_wrapper">
                                {equalCategoryArticle.slice(0, 3).map((item: any, id: any) => {
                                    return (
                                        <div key={id} className="recomend-content_wrap">
                                            <div className="recomend-content_wrap-item">
                                                <NavLink to={`/${language}/blogs/${item.id}`} style={{position: "relative"}}>
                                                    <img className="recomend-content_wrap-item-image"
                                                         src={
                                                             CONFIG.photoBaseUrl +
                                                             item.attributes.blog_img?.data[0].attributes.url
                                                         }
                                                    />
                                                    <div
                                                        className="recomend-content_wrap-item-date">{item.attributes.blog_date}</div>
                                                </NavLink>
                                                <div className="recomend-content_wrap-item-body">
                                                <div
                                                        className="recomend-content_wrap-item-desc">{item.attributes?.stage_title?.[lang]}</div>

                                                </div>
                                            </div>
                                        </div>

                                    )
                                })}
                            </div>
                        </div>
                        <div className="footer-blog-info__center-stars">
                            <div style={{color: "#FFDDBA", marginRight: 10}}>{t("articleRating")}</div>
                            <div className="footer-blog-info__center-stars-wrap">
                                <ReactStars
                                    count={5}
                                    value={rating || 4}
                                    size={24}
                                    isHalf={false}
                                    emptyIcon={<FontAwesomeIcon icon={faStar}/>}
                                    halfIcon={<FontAwesomeIcon icon={faStarHalfStroke}/>}
                                    fullIcon={<FontAwesomeIcon icon={faStar}/>}
                                    activeColor="#FFDDBA"
                                />
                            </div>
                            <div className="footer-blog-info__right">
                                <div>{`${t("view")}: ${blog.attributes.views}`}</div>
                                <div>
                                    {dataBlog}
                                </div>
                            </div>
                        </div>

                        {/*<FooterContentBlogInfo blog={blog} dataBlog={dataBlog}/>*/}
                    </div>
                ))}
        </>
    );
}

export default Blog
