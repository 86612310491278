import React, {useEffect, useState} from 'react';
import Navigation from "../Navigation/Navigation";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import "./index.scss"
import Loader from "../Loader/Loader";
import {useAppSelector} from "../../hooks/redux";

const TermsOfUse = () => {
    const {t} = useTranslation('translation', {i18n});
    const {burgerMenu} = useAppSelector(state => state.burgerMenuReducer)
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        window.scroll(0,0)
    }, [])
    return (
        <>
            <Navigation />
            {isLoading ? (
                <Loader/>
            ) : (
                !burgerMenu && (
                    <div className='terms-of-use'>
                        <div className='terms-of-use__wrap'>
                            <div className='terms-of-use__wrap-info'>
                                <div>
                                    <h2 className='terms-of-use__wrap-info-name'>
                                        {'Условия пользования'}
                                    </h2>
                                    <div>
                                        <p className="terms-of-use__wrap-info-text">
                                            {"Мы стремимся предоставлять вам, а также вашим друзьям и близким наиболее полезную информацию. Рекомендации знакомых бывают важны, поэтому ваши близкие, друзья и знакомые могут видеть имя и фотографию из вашего профиля, а также такой контент, как ваши отзывы и объявления, которым вы добавляете +1. Это происходит, только когда вы выполняете какое-либо действие (например, добавляете +1, оставляете комментарий или читаете ленту), и видеть подобную информацию могут только люди, которым вы предоставили доступ к своему контенту. Когда вы работаете с сервисами Google, вы сами выбираете, какими материалами делиться с другими. Данное обновление Условий использования никак не влияет на то, кому вы открывали доступ к своим материалам ранее и кому сможете предоставлять его в будущем.\n" +
                                                "\n" +
                                                "Благодаря обратной связи от знакомых вы можете экономить время и эффективнее использовать сервисы Google, в том числе Поиск, Карты, Play Маркет и рекламу. Например, ваши друзья могут увидеть, что вы поставили какому-либо альбому 4 звезды на странице группы в Google Play, а +1, добавленный вами любимой кондитерской, может отображаться в объявлении, которое эта кондитерская размещает при помощи Google. Подобные рекомендации мы называем открытыми отметками. Подробнее о них можно узнать здесь.\n" +
                                                "\n" +
                                                "Управлять использованием имени профиля и фотографии можно при помощи настроек открытых отметок. Если вы установите \"отключить\", имя и фотография из вашего профиля не будет показываться в рекламе вашей любимой кондитерской или любом другом объявлении. Настройка управляет только использованием данных в объявлениях, поэтому имя и фотография вашего профиля могут показываться в других сервисах, таких как Google Play.\n" +
                                                "\n" +
                                                "Если вы сообщали нам ранее, что не хотите, чтобы ваши +1 показывались в объявлениях, конечно, мы будем учитывать это даже после обновления Условий. Если вам ещё нет 18 лет, мы не будем показывать ваши данные в рекламе и некоторых других контекстах."}
                                        </p>
                                        <p className="terms-of-use__wrap-info-text">
                                            {"Мы стремимся предоставлять вам, а также вашим друзьям и близким наиболее полезную информацию. Рекомендации знакомых бывают важны, поэтому ваши близкие, друзья и знакомые могут видеть имя и фотографию из вашего профиля, а также такой контент, как ваши отзывы и объявления, которым вы добавляете +1. Это происходит, только когда вы выполняете какое-либо действие (например, добавляете +1, оставляете комментарий или читаете ленту), и видеть подобную информацию могут только люди, которым вы предоставили доступ к своему контенту. Когда вы работаете с сервисами Google, вы сами выбираете, какими материалами делиться с другими. Данное обновление Условий использования никак не влияет на то, кому вы открывали доступ к своим материалам ранее и кому сможете предоставлять его в будущем.\n" +
                                                "\n" +
                                                "Благодаря обратной связи от знакомых вы можете экономить время и эффективнее использовать сервисы Google, в том числе Поиск, Карты, Play Маркет и рекламу. Например, ваши друзья могут увидеть, что вы поставили какому-либо альбому 4 звезды на странице группы в Google Play, а +1, добавленный вами любимой кондитерской, может отображаться в объявлении, которое эта кондитерская размещает при помощи Google. Подобные рекомендации мы называем открытыми отметками. Подробнее о них можно узнать здесь.\n" +
                                                "\n" +
                                                "Управлять использованием имени профиля и фотографии можно при помощи настроек открытых отметок. Если вы установите \"отключить\", имя и фотография из вашего профиля не будет показываться в рекламе вашей любимой кондитерской или любом другом объявлении. Настройка управляет только использованием данных в объявлениях, поэтому имя и фотография вашего профиля могут показываться в других сервисах, таких как Google Play.\n" +
                                                "\n" +
                                                "Если вы сообщали нам ранее, что не хотите, чтобы ваши +1 показывались в объявлениях, конечно, мы будем учитывать это даже после обновления Условий. Если вам ещё нет 18 лет, мы не будем показывать ваши данные в рекламе и некоторых других контекстах."}
                                        </p>
                                        <p className="terms-of-use__wrap-info-text">
                                            {"Мы стремимся предоставлять вам, а также вашим друзьям и близким наиболее полезную информацию. Рекомендации знакомых бывают важны, поэтому ваши близкие, друзья и знакомые могут видеть имя и фотографию из вашего профиля, а также такой контент, как ваши отзывы и объявления, которым вы добавляете +1. Это происходит, только когда вы выполняете какое-либо действие (например, добавляете +1, оставляете комментарий или читаете ленту), и видеть подобную информацию могут только люди, которым вы предоставили доступ к своему контенту. Когда вы работаете с сервисами Google, вы сами выбираете, какими материалами делиться с другими. Данное обновление Условий использования никак не влияет на то, кому вы открывали доступ к своим материалам ранее и кому сможете предоставлять его в будущем.\n" +
                                                "\n" +
                                                "Благодаря обратной связи от знакомых вы можете экономить время и эффективнее использовать сервисы Google, в том числе Поиск, Карты, Play Маркет и рекламу. Например, ваши друзья могут увидеть, что вы поставили какому-либо альбому 4 звезды на странице группы в Google Play, а +1, добавленный вами любимой кондитерской, может отображаться в объявлении, которое эта кондитерская размещает при помощи Google. Подобные рекомендации мы называем открытыми отметками. Подробнее о них можно узнать здесь.\n" +
                                                "\n" +
                                                "Управлять использованием имени профиля и фотографии можно при помощи настроек открытых отметок. Если вы установите \"отключить\", имя и фотография из вашего профиля не будет показываться в рекламе вашей любимой кондитерской или любом другом объявлении. Настройка управляет только использованием данных в объявлениях, поэтому имя и фотография вашего профиля могут показываться в других сервисах, таких как Google Play.\n" +
                                                "\n" +
                                                "Если вы сообщали нам ранее, что не хотите, чтобы ваши +1 показывались в объявлениях, конечно, мы будем учитывать это даже после обновления Условий. Если вам ещё нет 18 лет, мы не будем показывать ваши данные в рекламе и некоторых других контекстах."}
                                        </p>
                                        <p className="terms-of-use__wrap-info-text">
                                            {"Мы стремимся предоставлять вам, а также вашим друзьям и близким наиболее полезную информацию. Рекомендации знакомых бывают важны, поэтому ваши близкие, друзья и знакомые могут видеть имя и фотографию из вашего профиля, а также такой контент, как ваши отзывы и объявления, которым вы добавляете +1. Это происходит, только когда вы выполняете какое-либо действие (например, добавляете +1, оставляете комментарий или читаете ленту), и видеть подобную информацию могут только люди, которым вы предоставили доступ к своему контенту. Когда вы работаете с сервисами Google, вы сами выбираете, какими материалами делиться с другими. Данное обновление Условий использования никак не влияет на то, кому вы открывали доступ к своим материалам ранее и кому сможете предоставлять его в будущем.\n" +
                                                "\n" +
                                                "Благодаря обратной связи от знакомых вы можете экономить время и эффективнее использовать сервисы Google, в том числе Поиск, Карты, Play Маркет и рекламу. Например, ваши друзья могут увидеть, что вы поставили какому-либо альбому 4 звезды на странице группы в Google Play, а +1, добавленный вами любимой кондитерской, может отображаться в объявлении, которое эта кондитерская размещает при помощи Google. Подобные рекомендации мы называем открытыми отметками. Подробнее о них можно узнать здесь.\n" +
                                                "\n" +
                                                "Управлять использованием имени профиля и фотографии можно при помощи настроек открытых отметок. Если вы установите \"отключить\", имя и фотография из вашего профиля не будет показываться в рекламе вашей любимой кондитерской или любом другом объявлении. Настройка управляет только использованием данных в объявлениях, поэтому имя и фотография вашего профиля могут показываться в других сервисах, таких как Google Play.\n" +
                                                "\n" +
                                                "Если вы сообщали нам ранее, что не хотите, чтобы ваши +1 показывались в объявлениях, конечно, мы будем учитывать это даже после обновления Условий. Если вам ещё нет 18 лет, мы не будем показывать ваши данные в рекламе и некоторых других контекстах."}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            )}
        </>
    );
};

export default TermsOfUse;