import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {Provider} from "react-redux";
import {setupStore} from "./store/store";
import {BrowserRouter} from "react-router-dom";

let currentPageUrl = window.location.href;
let canonicalLink = document.createElement('link');
canonicalLink.rel = 'canonical';
canonicalLink.href = currentPageUrl;

document.head.appendChild(canonicalLink);

const store = setupStore()
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <App/>
        </Provider>
    </BrowserRouter>
);


