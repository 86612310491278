import React, {useEffect} from 'react';
import {IImages} from "../../../models/IImage"
import "./index.scss"
import {CONFIG} from "../../../config";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

interface EightImageBlockProps {
    images: IImages
    setImageHeight: any
    imageHeight: any
    imgRef: any
}

const EightImageBlock = ({images, setImageHeight, imageHeight, imgRef}: EightImageBlockProps) => {

    useEffect(() => {

        if (imgRef.current) {
            setImageHeight(imgRef.current.clientHeight);
        }
    }, []);

    return (
        <div className="eight-image-block" ref={imgRef}>
            {images.data.map((item, index) =>
                <div key={index} className="eight-image-block__item">
                    <img
                        src={CONFIG.photoBaseUrl + item.attributes?.url}
                        alt="Photo"
                    />
                </div>
            )}
        </div>
    );
};

export default EightImageBlock;
