import React, {useEffect, useRef, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../hooks/redux'
import {fetchInfoAboutUs} from '../../store/reducers/ActionCreators'
import './index.scss'
import Navigation from '../../components/Navigation/Navigation'
import {useTranslation} from 'react-i18next'
import Loader from '../../components/Loader/Loader'
import {CONFIG} from '../../config'
import i18n from "i18next";
import EightImageBlock from "../../components/BlockForm/EightImageBlock/EightImageBlock";
import ReactMarkdown from "react-markdown";
import BreadCrumbsComponent from "../../components/BreadCrumbsComponent/BreadCrumbsComponent";

const AboutUs = ( ) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [textHeight, setTextHeight] = useState<number>(0);
    const [imageHeight, setImageHeight] = useState<number>(0);
    const textRef = useRef<HTMLDivElement>(null);
    const imgRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation('translation', { i18n });
    const dispatch = useAppDispatch()
    const {info, isLoading} = useAppSelector(state => state.aboutUsReducer)
    const {lang} = useAppSelector(state => state.settingsReducer)
    const {burgerMenu} = useAppSelector(state => state.burgerMenuReducer)

    useEffect(() => {
        dispatch(fetchInfoAboutUs())
    }, [])

    useEffect(() => {
        if (textRef.current) {
            setTextHeight(textRef.current.clientHeight);
        }
    }, [isLoading]);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <Navigation />
            {isLoading ? (
                <Loader/>
            ) : (
                !burgerMenu && (
                    <div className='about-us'>
                        <div className='about-us__company'>
                            <div className='about-us__company__info'>
                                <div>
                                    <h2 className='about-us__company__info__name'>
                                        {t('Studio “Riberry”')}
                                    </h2>
                                </div>
                                <BreadCrumbsComponent
                                    titleHome={`${t("Home").toUpperCase()}`}
                                    titleOne={`${t("About").toUpperCase()}`}
                                    linkHome={`/${i18n.language}/`}
                                />
                                <div className='wrapper-blog'>
                                    <div ref={textRef} className='about-us__company__example'>
                                        <EightImageBlock
                                            imageHeight={imageHeight}
                                            setImageHeight={setImageHeight}
                                            images={info.attributes.images}
                                            imgRef={imgRef}
                                        />
                                    </div>
                                    <div className='about-us__company-body'>


                                        <div className='about-us__company__info__textP'>
                                            <div className='wrap-text'>
                                                <p>
                                                    {t(
                                                        '"Riberry" is a unique interior design and engineering studio, which has no analogues in the Baku market.'
                                                    )}
                                                </p>
                                                <p>
                                                    {t(
                                                        'Our distinguishing feature is our focus on high world standards in the field of design and architecture, which makes it easier to work with clients, make it comfortable, easy and fast, as well as achieve the best result in the shortest possible time!'
                                                    )}
                                                </p>
                                                <p>
                                                    {t(
                                                        '"Riberry" studio was founded in 2013. For more than ten years of work, we have designed more than 400 successful projects in Azerbaijan, as well as in Russia, Kazakhstan, Turkey and Israel.'
                                                    )}
                                                </p>
                                                <p>
                                                    {t("Reviews of grateful clients inspire us with confidence and give us the right to call ourselves professionals in the field of design and architecture!")}
                                                </p>
                                                <p>
                                                    {t(
                                                        "Our philosophy is to strive to ensure that the result exceeds the client's expectations, which is why the studio works in different styles and directions, so that each client can find what he likes with us!"
                                                    )}
                                                </p>
                                            </div>
                                            <div className={`content ${isExpanded ? 'expanded' : ''} wrap-text`}>
                                                <p>
                                                    {t(
                                                        'We give guarantees for our work and offer a comprehensive service for the implementation of your turnkey design project by our company.'
                                                    )}
                                                    {t(
                                                        'But the most important thing is love for your work, thanks to which you get a truly high-quality, stylish and beautiful design with well-thought-out ergonomics, placement of furniture, appliances and communications!'
                                                    )}
                                                    {t(
                                                        'We are sure that by contacting us, you will be satisfied and will remember with gratitude about our studio "Riberry"!'
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='about-us_mob'>
                            <h2 className='about-us__person__post-mobile'>
                                {info.attributes.stage_head?.[lang]}
                            </h2>
                        </div>
                        <div className='about-us__person'>
                            <div className='about-us__person__photo'>
                                <img
                                    src={
                                        CONFIG.photoBaseUrl +
                                        info.attributes.personPhoto.data[0]?.attributes.url
                                    }
                                    alt='Photo'
                                />
                            </div>
                            <div className='about-us__person__bio'>
                                <h2 className='about-us__person__post'>
                                    {info.attributes.stage_head?.[lang]}
                                </h2>
                                <h1 className='about-us__person__name'>
                                    {info.attributes.stage_title?.[lang]}
                                </h1>
                                <ReactMarkdown>{info.attributes.stage_desc?.[lang]}</ReactMarkdown>
                            </div>
                        </div>
                    </div>
                )
            )}
        </>
    )
}

export default AboutUs
