import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchQuestionsAnswers} from "./ActionCreators";
import {IAnswersQuestion, IAnswersQuestionResponse} from "../../models/IAnswersQuestion";

interface AnswersQuestionState {
    isLoading: boolean,
    error: string,
    answersQuestions: IAnswersQuestion[],
}

const initialState: AnswersQuestionState = {
    isLoading: false,
    error: '',
    answersQuestions:[],

}
export const answersQuestionsSlice = createSlice({
    name: 'answers/questions',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchQuestionsAnswers.fulfilled.type]: (state, action: PayloadAction<IAnswersQuestion[]>) => {
            state.isLoading = false
            state.error = ''
            state.answersQuestions = action.payload
        },
        [fetchQuestionsAnswers.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchQuestionsAnswers.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        }
    }
})
export default answersQuestionsSlice.reducer