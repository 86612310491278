import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchArchitecturePrice, fetchDesignPrice } from './ActionCreators'
import { IPrice } from '../../models/IPrice'

interface PartnersSlice {
	isLoading: boolean
	error: string
	designPrices: IPrice[]
	architecturePrices: IPrice[]
}

const initialState: PartnersSlice = {
	isLoading: false,
	error: '',
	designPrices: [],
	architecturePrices: []
}
export const priceSlice = createSlice({
	name: 'price',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchDesignPrice.fulfilled.type]: (
			state,
			action: PayloadAction<IPrice[]>
		) => {
			state.isLoading = false
			state.error = ''
			state.designPrices = action.payload
		},
		[fetchDesignPrice.pending.type]: state => {
			state.isLoading = true
		},
		[fetchDesignPrice.rejected.type]: (
			state,
			action: PayloadAction<string>
		) => {
			state.isLoading = false
			state.error = action.payload
		},
		[fetchArchitecturePrice.fulfilled.type]: (
			state,
			action: PayloadAction<IPrice[]>
		) => {
			state.isLoading = false
			state.error = ''
			state.architecturePrices = action.payload
		},
		[fetchArchitecturePrice.pending.type]: state => {
			state.isLoading = true
		},
		[fetchArchitecturePrice.rejected.type]: (
			state,
			action: PayloadAction<string>
		) => {
			state.isLoading = false
			state.error = action.payload
		}
	}
})
export default priceSlice.reducer
