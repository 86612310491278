import axios from 'axios'
import {createAsyncThunk} from '@reduxjs/toolkit'
import {IImages} from '../../models/IImage'
import {IAboutUs, IAboutUsResponse} from '../../models/IAboutUs'
import {AppDispatch} from '../store'
import {photoSlice} from './photoSlice'
import {Language} from '../../utils/enum'
import {IPartnersResponse} from '../../models/IPartners'
import {IPriceResponse} from '../../models/IPrice'
import {IPhotoTotalSingleInfo} from '../../models/IPhoto'
import {CONFIG} from '../../config'
import {IAnswersQuestionResponse} from "../../models/IAnswersQuestion";

export const selectNavPage = (page: string) => ({
    type: 'photos/selectNavPage',
    payload: page
})
export const openBurgerMenu = (value: boolean) => ({
    type: 'burger/openBurger',
    payload: value
})
export const openFilterMenu = (value: boolean) => ({
    type: 'burger/openFilter',
    payload: value
})
export const openSelectedFilter = (value: boolean) => ({
    type: 'burger/openSelectedFilter',
    payload: value
})
export const addTypeAction = (value: string) => ({
    type: 'filter/addType',
    payload: value
})
export const addStyleAction = (value: string) => ({
    type: 'filter/addStyle',
    payload: value
})
export const deleteTypeAction = (value: string) => ({
    type: 'filter/deleteType',
    payload: value
})
export const deleteStyleAction = (value: string) => ({
    type: 'filter/deleteStyle',
    payload: value
})
export const clearAllStylesAction = () => ({
    type: 'filter/clearAllStyles'
})
export const clearAllTypesAction = () => ({
    type: 'filter/clearAllTypes'
})

export const clearPhotoState = () => ({
    type: 'photos/clearState'
})
export const changeLangAction = (lang: Language) => ({
    type: 'settings/changeLang',
    payload: lang
})
export const loadingStartAction = (lang: Language) => ({
    type: 'settings/changeLang',
    payload: lang
})

export const addCountBlog = (count: number) => ({
    type: 'blog/countBlog',
    payload: count
})

export const fetchPhotos = createAsyncThunk(
    'photo/fetchAll',
    async (_, thunkApi) => {
        const response = await axios.get<IImages>(
            CONFIG.baseUrl + '/items?populate=*'
        )
        return response.data.data
    }
)
export const fetchPhotoById = createAsyncThunk(
    'photo/fetchAll',
    async (id: string | undefined, thunkApi) => {
        const response = await axios.get<IPhotoTotalSingleInfo>(
            CONFIG.baseUrl +
            `/items/${id}?populate[photos][populate]=*&populate[desc]=*&populate[title]=*`
        )
        return response.data.data
    }
)

export const fetchPhotosByFilter =
    (
        selectedType: string[],
        selectedStyle: string[],
        selectedPage?: string,
        page?: number
    ) =>
        async (dispatch: AppDispatch) => {
            try {
                const typeFilter = selectedType.join('&filters[place_type]=')
                const styleFilter = selectedStyle?.join('&filters[place_style]=')
                const selectedFilter = selectedPage?.substring(1) || ''
                dispatch(photoSlice.actions.photosByFilterFetching())
                // const response = await axios.get<any>(
                // 	CONFIG.baseUrl +
                // 	`/items?${
                // 		typeFilter?.length > 0 ? `filters[place_type]=` + typeFilter : ''
                // 	}${
                // 		styleFilter?.length > 0
                // 			? `&filters[place_style]=` + styleFilter
                // 			: ''
                // 	}${
                // 		selectedFilter?.length > 0
                // 			? `&filters[kind_of_place]=` + selectedFilter
                // 			: ''
                // 	}&pagination[page]=${page}&pagination[pageSize]=100&populate[photos][populate]=*&populate[desc]=*&populate[title]=*&populate[city]=*&populate[country]=*&sort=rank:asc`
                // )
                // dispatch(photoSlice.actions.photosByFilterFetchingSuccess(response.data))
            } catch (e: any) {
                dispatch(photoSlice.actions.photosByFilterFetchingError(e.message))
            }
        }

export const photosByPagination =
    (
        selectedType: string[],
        selectedStyle: string[],
        selectedPage?: string,
        page?: number,
    ) =>
        async (dispatch: AppDispatch) => {
            try {
                const typeFilter = selectedType.join('&filters[photos][placeStyleType][place_type][$eq]=')
                const styleFilter = selectedStyle?.join('&filters[place_style]=')
                const selectedFilter = selectedPage?.substring(1) || ''
                const location = window.location.pathname
                const isLocation = location === '/' ? "/interior" : window.location.pathname
                const isLocationSubs = isLocation?.substring(1) || ''
                const finalResult = isLocationSubs.replace(/^(en|ru|az)\//, '')
                const initionalRoute = finalResult.length === 0 ? 'interior' : finalResult

                dispatch(photoSlice.actions.photosByPaginationFetching())
                const response = await axios.get(
                    CONFIG.baseUrl +
                    `/items?${
                        typeFilter?.length > 0 ? `filters[photos][placeStyleType][place_type][$eq]=` + typeFilter : ''
                    }${
                        styleFilter?.length > 0
                            ? `&filters[place_style]=` + styleFilter
                            : ''
                    }${
                        `&filters[kind_of_place]=` + initionalRoute.replace(/\/$/, '')
                    }&pagination[page]=${page}&pagination[pageSize]=10&populate[photos][populate]=*&populate[desc]=*&populate[title]=*&populate[city]=*&populate[country]=*&sort=rank:asc`
                )
                if (response.status === 200) {
                    dispatch(
                        photoSlice.actions.photosByPaginationFetchingSuccess(response.data)
                    )
                }
            } catch (e: any) {
                dispatch(photoSlice.actions.photosByPaginationFetchingError(e.message))
            }
        }

export const fetchInfoAboutUs = createAsyncThunk(
    'aboutUs/about',

    async (_, thunkApi) => {
        const response = await axios.get<IAboutUsResponse>(
            CONFIG.baseUrl + '/about-uses?populate=*'
        )
        return response.data.data[0]
    }
)
export const fetchPartners = createAsyncThunk(
    'partners/get',

    async (_, thunkApi) => {
        const response = await axios.get<IPartnersResponse>(
            CONFIG.baseUrl + '/partners?populate=*'
        )
        return response.data.data
    }
)

export const fetchServicePricesStages = createAsyncThunk(
    'servicePrices/stages',

    async (_, thunkApi) => {
        const response = await axios.get<IAboutUsResponse>(
            CONFIG.baseUrl + '/design-stages?populate=*'
        )
        return response.data.data
    }
)
export const fetchArchStages = createAsyncThunk(
    'servicePrices/arch',

    async (_, thunkApi) => {
        const response = await axios.get<IAboutUsResponse>(
            CONFIG.baseUrl + '/architectures?populate=*'
        )
        return response.data.data
    }
)
export const fetchRepairStages = createAsyncThunk(
    'servicePrices/repair',

    async (_, thunkApi) => {
        const response = await axios.get<IAboutUsResponse>(
            CONFIG.baseUrl + '/repairs?populate=*'
        )
        return response.data.data
    }
)

export const fetchDesignPrice = createAsyncThunk(
    'price/design-prices',

    async (_, thunkApi) => {
        const response = await axios.get<IPriceResponse>(
            CONFIG.baseUrl + '/design-prices'
        )
        return response.data.data
    }
)
export const fetchArchitecturePrice = createAsyncThunk(
    'price/architecture-prices',

    async (_, thunkApi) => {
        const response = await axios.get<IPriceResponse>(
            CONFIG.baseUrl + '/architecture-prices'
        )
        return response.data.data
    }
)
export const fetchBlogs = createAsyncThunk('blogs', async (_, thunkApi) => {
    const response = await axios.get<any>(`${CONFIG.baseUrl}/blog-infos
	?populate[stage_desc]=*
	&populate[blog_img]=*
	&populate[stage_title]=*
	&populate[stage_head]=*
	`)
    return response.data.data
})

export const fetchBlog = createAsyncThunk(
    'blog',
    async (id: string | undefined, thunkApi) => {
        try {
            const currentBlogResponse = await axios.get<any>(`${CONFIG.baseUrl}/blog-infos/${id}?populate=*`);
            const currentBlog = currentBlogResponse.data.data;

            const updatedViews = (+currentBlog.attributes.views || 0) + 1;
            const updateViewsResponse = await axios.put<any>(
                `${CONFIG.baseUrl}/blog-infos/${id}
						?populate[blog_contents][populate]=*
						&populate[stage_title]=*
						&populate[stage_desc]=*
						&populate[stage_head]=*
						&populate[recommended_articles][populate]=*
						&populate[blog_img]=*`,
                {
                    data: {
                        views: updatedViews,
                    }
                }
            );

            return updateViewsResponse.data.data;
        } catch (error) {
            console.error('Ошибка при получении/обновлении блога:', error);
            throw error;
        }
    }
);
export const fetchQuestionsAnswers = createAsyncThunk(
    'answers/questions',

    async (_, thunkApi) => {
        const response = await axios.get<IAnswersQuestionResponse>(
            CONFIG.baseUrl + '/question-answers?populate=*'
        )
        return response.data.data
    }
)

export const repairDataFetch = createAsyncThunk(
    "data/repairData",
    async (formRepairData: any, thunkApi) => {
        try {
            const res = await axios.post<any>(
                // `${CONFIG.baseUrl}/repair-forms`,
                `${CONFIG.baseUrl}/contact`,
                {
                    data: {
                        name: formRepairData.name,
                        email: formRepairData.email,
                        quadrature: formRepairData.quadrature,
                        place: formRepairData.place,
                        isDesign: formRepairData.isDesign
                    }
                }
            );

            return res;
        } catch (e) {
            return thunkApi.rejectWithValue("Не удалось загрузить");
        }
    }
);

export const contactsFormFetch = createAsyncThunk(
    "data/contactData",
    async (formData: any, thunkApi) => {
        try {
            const res = await axios.post<any>(
                `${CONFIG.baseUrl}/contact-forms`,
                {
                    data: {
                        name: formData.name,
                        email: formData.email,
                        message: formData.message,
                    }
                }
            );

            return res;
        } catch (e) {
            return thunkApi.rejectWithValue("Не удалось загрузить");
        }
    }
);
