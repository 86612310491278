import React, {FC, useMemo} from 'react'
import { useInView } from 'react-intersection-observer';
import {useTranslation} from 'react-i18next'
import {IBlock} from '../../../models/IBlock'
import {findItemByOrder} from '../../../utils/findItemByOrder'
import {useAppSelector} from "../../../hooks/redux";
import './index.scss'

const Triple: FC<IBlock> = ({item, baseUrl, reverse = false, page, dataLoaded}) => {
    const {selectedType, selectedStyle} = useAppSelector(
        state => state.filterReducer
    )
    const {t} = useTranslation()
    const {photos} = item.attributes
    const filterPhotos = selectedType?.length > 0 ? photos.filter(item => selectedType.includes(item.placeStyleType?.place_type)) : photos
    const {firstItemUrl, secondItemUrl, thirdItemUrl} = useMemo(
        () => findItemByOrder(filterPhotos),
        [item]
    )
    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
      });

    const typeName = ["Storehouse", "Wardrobe", "Bathroom", "Laundry", "Pantry", "Restroom"]
    const isFirstTypeSelected = selectedType.length > 0 && typeName.includes(selectedType[0]);
    return (
        <div>
            {!isFirstTypeSelected ?
                <div
                    ref={ref}
                    style={{
                        display: 'flex',
                        flexDirection: reverse ? 'column-reverse' : 'column'
                    }}
                >
                    <div className='triple__row-1'>
                        {inView ?
                            (
                                <div
                                    className='triple__row-1__item'
                                    style={{border: firstItemUrl ? "none" : "4px solid white"}}
                                >
                                    {!firstItemUrl ? (
                                        <div style={{display: 'flex', justifyContent: 'center'}}>{`${t(
                                            'No Photo'
                                        )}`}</div>
                                    ) : (
                                        <img src={baseUrl + firstItemUrl!} alt='Photo'/>
                                    )}
                                </div>
                            )
                            :
                            (
                                <div className='triple__row-1__item-skeleton'></div>
                            )
                        }

                        {
                            inView ?
                            (
                                <div
                                    className='triple__row-1__item'
                                    style={{border: secondItemUrl ? "none" : "4px solid white"}}
                                >
                                    {!secondItemUrl ? (
                                        <div style={{display: 'flex', justifyContent: 'center'}}>{`${t(
                                            'No Photo'
                                        )}`}</div>
                                    ) : (
                                        <img src={baseUrl + secondItemUrl!} alt='Photo'/>
                                    )}
                                </div>
                            )
                            :
                            (
                                <div className='triple__row-1__item-skeleton'></div>
                            )
                        }
                    </div>
                    {
                        inView ?
                        (
                            <div
                                className='triple__row-2'
                                style={{border: thirdItemUrl ? "none" : "4px solid white"}}
                            >
                                <div className='triple__row-2__item'>
                                    {!thirdItemUrl ? (
                                        <div style={{display: 'flex', justifyContent: 'center'}}>{`${t(
                                            'No Photo'
                                        )}`}
                                        </div>
                                    ) : (
                                        <img src={baseUrl + thirdItemUrl!} alt='Photo'/>
                                    )}
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className='triple__row-2__item-skeleton'></div>
                        )
                    }

                </div>
                :
                <div
                    ref={ref}
                    style={{
                        display: 'flex',
                        flexDirection: reverse ? 'column-reverse' : 'column'
                    }}
                >
                    <div className='triple__row-1-spec'>
                        {
                            inView ?
                            (
                                <div
                                    className='triple__row-1-spec__item'
                                    style={{border: firstItemUrl ? "none" : "4px solid white"}}
                                >
                                    {!firstItemUrl ? (
                                        <div
                                            className="triple__row-1-spec__item-one"
                                            style={{display: 'flex', justifyContent: 'center'}}
                                        >{`${t('No Photo')}`}</div>
                                    ) : (
                                        <img src={baseUrl + firstItemUrl!} alt='Photo'/>
                                    )}
                                </div>
                            )
                            :
                            (
                                <div className='triple__row-1__item-skeleton'></div>
                            )
                        }

                        {
                            inView ?
                            (
                                <div
                                    className='triple__row-1-spec__item'
                                    style={{border: secondItemUrl ? "none" : "4px solid white"}}
                                >
                                    {!secondItemUrl ? (
                                        <div
                                            className="triple__row-1-spec__item-two"
                                            style={{display: 'flex', justifyContent: 'center'}}
                                        >{`${t('No Photo')}`}</div>
                                    ) : (
                                        <img src={baseUrl + secondItemUrl!} alt='Photo'/>
                                    )}
                                </div>
                            )
                            :
                            (
                                <div className='triple__row-1__item-skeleton'></div>
                            )
                        }
                    </div>
                    {
                        inView ?
                        (
                            <div className='triple__row-2-spec'>
                                <div
                                    className='triple__row-2-spec__item'
                                    style={{border: thirdItemUrl ? "none" : "4px solid white"}}
                                >
                                    {!thirdItemUrl ? (
                                        <div
                                            className="triple__row-1-spec__item-three"
                                            style={{display: 'flex', justifyContent: 'center'}}
                                        >{`${t('No Photo')}`}
                                        </div>
                                    ) : (
                                        <img src={baseUrl + thirdItemUrl!} alt='Photo'/>
                                    )}
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className='triple__row-2__item-skeleton'></div>
                        )
                    }

                </div>
            }
        </div>
    )
}

export default Triple
