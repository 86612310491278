import React from 'react'
import { IPhoto } from '../../models/IPhoto'
import { useAppSelector } from '../../hooks/redux'
import { useTranslation } from 'react-i18next'

interface InfoBlockProps {
	item: IPhoto
}

const InfoBlock = ({ item }: InfoBlockProps) => {

	const { lang } = useAppSelector(state => state.settingsReducer)
	const { t } = useTranslation()

	return (
		<div className='block_info'>
			<div className='block_info-square'>
				{t(item?.attributes.object_type)}, {t(item?.attributes.place_style)}, {item?.attributes.area} {t('m²')}
			</div>
			<div className='block_info-title'>{item?.attributes.title?.[lang]}</div>
			<div className='block_info-country'>
				{item?.attributes.city?.[lang]}, {item?.attributes.country?.[lang]}
			</div>
		</div>
	)
}
export default InfoBlock
