import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'

import './index.scss'
import InteriorDesign from '../interiorDesign'
import Architecture from '../architecture'
import Repair from '../repair'
import Navigation from '../../components/Navigation/Navigation'
import { useTranslation } from 'react-i18next'
import BreadCrumbsComponent from "../../components/BreadCrumbsComponent/BreadCrumbsComponent";
import i18n from "i18next";

const baseUrl = 'http://riberry.az/api'

const ServicesPrices = () => {
	const dispatch = useAppDispatch()
	const { t, i18n } = useTranslation()
	const [activeTab, setActiveTab] = useState('tab1')
	const { burgerMenu } = useAppSelector(state => state.burgerMenuReducer)
	return (
		<>
			<Navigation />

			{!burgerMenu && (
				<div className='Tabs'>
					{/* Tab nav */}
					<ul className='nav'>
						<li
							className={activeTab === 'tab1' ? 'selected-tab' : ''}
							onClick={() => setActiveTab('tab1')}
							style={{ padding: "16px 16px 16px 0"}}
						>
							{t('Interior design')}
						</li>
						<li
							className={activeTab === 'tab2' ? 'selected-tab' : ''}
							onClick={() => setActiveTab('tab2')}
						>
							{t('Architecture, landscape')}
						</li>
						<li
							className={activeTab === 'tab3' ? 'selected-tab' : ''}
							onClick={() => setActiveTab('tab3')}
						>
							{t('Repair')}
						</li>
					</ul>
					<div className='outlet'>
						<div className="bread-crumbs-wrap">
							<BreadCrumbsComponent
								titleHome={`${t("Home").toUpperCase()}`}
								titleOne={`${t("Services and prices").toUpperCase()}`}
								linkHome={`/${i18n.language}/`}
								arrowRightOne={" > "}
								titleTwo={
									activeTab === "tab1" ? `${t("Interior design").toUpperCase()}` :
										activeTab === "tab2" ? `${t("Architecture, landscape").toUpperCase()}` :
											activeTab === "tab3" ? `${t("Repair").toUpperCase()}` :
												""
								}
							/>
						</div>

						{activeTab === 'tab1' ? (
							<InteriorDesign />
						) : activeTab === 'tab2' ? (
							<Architecture />
						) : (
							<Repair />
						)}
					</div>
				</div>
			)}
		</>
	)
}

export default ServicesPrices
