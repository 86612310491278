//@ts-nocheck
import React, {useEffect, useRef, useState} from 'react'
import {NavLink, useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../hooks/redux'
import {fetchPhotoById, openFilterMenu} from '../../store/reducers/ActionCreators'
import './index.scss'
import ReactMarkdown from 'react-markdown'
import ImageGallery from 'react-image-gallery'
import {useTranslation} from 'react-i18next'
import Loader from '../../components/Loader/Loader'
import {CONFIG} from '../../config'
import "react-image-gallery/styles/css/image-gallery.css";

const arrowLeft = require('../../assets/arrow-left.svg').default
const closeBtn = require('../../assets/closeBtn.svg').default

const MoreInfo = () => {
    const params = useParams()
    const navigate = useNavigate();
    const [showMagnifier, setShowMagnifier] = useState(false);
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const {t, i18n} = useTranslation()
    const [readMore, setReadMore] = useState(false)
    const {lang} = useAppSelector(state => state.settingsReducer)
    const {photosById, error, isLoading} = useAppSelector(
        state => state.photoByIdReducer
    )
    const carouselPhotos = photosById?.attributes?.photos || []
    const images = [
        ...carouselPhotos?.map(p => ({
            original: CONFIG.photoBaseUrl + p.photo.data?.attributes?.formats?.large.url,
            thumbnail: CONFIG.photoBaseUrl + p.photo.data?.attributes?.formats?.large.url
        }))
    ]
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000);

        dispatch(fetchPhotoById(params.id))
    }, [])
    const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
            if (document.fullscreenElement) {
                document.exitFullscreen()
                    .catch(err => console.error('Failed to exit fullscreen:', err));
            }
            setShowMagnifier(false);
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                setShowMagnifier(false);
            }
        };

        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                if (document.fullscreenElement) {
                    document.exitFullscreen()
                        .then(() => {
                            setShowMagnifier(false);
                        })
                        .catch(err => console.error('Failed to exit fullscreen:', err));
                }
            }
        };

        window.addEventListener('fullscreenchange', handleFullscreenChange);
        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('fullscreenchange', handleFullscreenChange);
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const openFullscreen = (image) => {
        setFullscreenImage(image);
    };

    const closeFullscreen = () => {
        setFullscreenImage(null);

        if (document.fullscreenElement) {
            document.exitFullscreen()
                .catch(err => console.error('Failed to exit fullscreen:', err));
        }
        setShowMagnifier(false);
    };

    const handlePrevClick = () => {

        const newIndex = (currentImageIndex - 8 + images.length) % images.length;
        setCurrentImageIndex(newIndex);
    };

    const handleNextClick = () => {
        const newIndex = (currentImageIndex + 8) % images.length;
        setCurrentImageIndex(newIndex);
    };

    const toggleFullscreen = () => {
        const elem = document.querySelector(".image-gallery-wrap");

        if (elem) {
            if (!document.fullscreenElement) {
                if (elem.requestFullscreen) {
                    elem.requestFullscreen()
                        .then(() => {
                            setShowMagnifier(true);
                        })
                        .catch(err => console.error('Failed to enter fullscreen:', err));
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen()
                        .then(() => {
                            setShowMagnifier(true);
                        })
                        .catch(err => console.error('Failed to enter fullscreen:', err));
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen()
                        .then(() => {
                            setShowMagnifier(true);
                        })
                        .catch(err => console.error('Failed to enter fullscreen:', err));
                } else {
                    console.error('Fullscreen mode is not supported in this browser.');
                }
            } else {
                document.exitFullscreen()
                    .then(() => {
                        setShowMagnifier(false);
                    })
                    .catch(err => console.error('Failed to exit fullscreen:', err));
            }
        } else {
            console.error('Element not found');
        }
    };

    const startIndex = currentImageIndex % images.length;
    const endIndex = (startIndex + 8) % images.length;

    let displayedImages = [];
    if (startIndex <= endIndex) {
        displayedImages = images.slice(startIndex, endIndex + 1);
    } else {
        displayedImages = [...images.slice(startIndex), ...images.slice(0, endIndex + 1)];
    }

    return isLoading ? (
        <Loader/>
    ) : (
        <div className='about-repair__wrapper'>
            <div className='about-repair__mobile'>
                <div className='about-repair__mobile-top'>
                    <NavLink to='/'>
                        <img src={arrowLeft} alt='arrow-left'/>
                    </NavLink>
                    <div className='about-repair__mobile-top__title'>
                        {t(photosById?.attributes.place_type as string)}
                        {t(photosById?.attributes.place_style as string)},
                        {photosById?.attributes.area} m²
                    </div>
                </div>
                <div className='about-repair__mobile-top__desc'>
                    {photosById?.attributes?.desc?.[lang]?.length! > 100 && readMore
                        ? photosById?.attributes.desc?.[lang]
                        : photosById?.attributes.desc?.[lang]?.slice(0, 100)}
                    {!readMore && photosById?.attributes.desc?.[lang]?.length! > 100 ? (
                            <span
                                onClick={() => setReadMore(true)}
                                className='about-repair__mobile-top__desc-more'
                            >
							 {t(' read more')}
						</span>)
                        :
                        (<span
                                onClick={() => {
                                    setReadMore(false);
                                    window.scroll(0, 0);
                                }}
                                className='about-repair__mobile-top__desc-hide'
                            >
							{t('hide text')}
						</span>

                        )}
                </div>
            </div>
            <NavLink to={`/${window.location.pathname.split('/')[1]}/`} className='about-repair__wrapper-close__btn'>
                {!showMagnifier && <img src={closeBtn} alt='Close'/>}
            </NavLink>
            <div className="image-gallery-wrap">
                <div >
                    {!showMagnifier ? <><ImageGallery
                        showPlayButton={false}
                        autoPlay={false}
                        showFullscreenButton={false}
                        items={displayedImages}
                        startIndex={0}
                        renderLeftNav={( disabled) => (
                            <div
                                className="left-nav"
                                onClick={() => {
                                    const newIndex = (currentImageIndex - 1 + images.length) % images.length;
                                    setCurrentImageIndex(newIndex);
                                }}
                            >
                                <img className="left-nav-img" src={require("../../assets/prev-arrow.svg").default} alt='prev-arrow'/>
                            </div>
                        )}
                        renderRightNav={( disabled) => (
                            <div
                                className="right-nav"
                                onClick={() => {
                                    const newIndex = (currentImageIndex + 1) % images.length;
                                    setCurrentImageIndex(newIndex);
                                }}
                            >
                                <img className="right-nav-img" src={require("../../assets/next-arrow.svg").default} alt='next-arrow'/>
                            </div>
                        )}
                        onClick={(e) => {
                            const clickedImageIndex = images.findIndex(image => image.original === e.target.src);
                            setCurrentImageIndex(clickedImageIndex);
                            openFullscreen(e.target.src);
                            toggleFullscreen()
                        }}

                    />
                        <div className="image-gallery-btn-wrap">
                            <div className="image-gallery-btn-wrap-prev"
                                 onClick={handlePrevClick}
                            >
                                <img src={require("../../assets/prev-arrow.svg").default} alt='prev-arrow'/>
                            </div>
                            <div className="image-gallery-btn-wrap-next"
                                 onClick={handleNextClick}
                            >
                                <img src={require("../../assets/next-arrow.svg").default} alt='next-arrow'/>
                            </div>
                        </div>
                    </> : null}

                    {fullscreenImage && showMagnifier && (
                        <div className="fullscreen-overlay">
                            <img src={fullscreenImage} alt="Fullscreen" className="fullscreen-image"/>
                            <div className="fullscreen-overlay-close" onClick={closeFullscreen}>
                                <img src={closeBtn} alt='Close'/>
                            </div>
                        </div>
                    )}
                </div>

            </div>
            <div className='about-repair__mobile-photos'>
                {images.map((image, id) => (
                    <img
                        className='about-repair__mobile-photo'
                        src={image.original}
                        alt='Photo'
                        key={id}
                    />
                ))}
            </div>
            <div className='about-repair'>
                <div className='about-repair__title'>
                    {photosById?.attributes?.title?.[lang]},{' '}
                    {photosById?.attributes?.city?.[lang]} {photosById?.attributes?.area}{' '}
                    {t('m²')}
                </div>

                <div className='about-repair__description'>
                    <ReactMarkdown>{photosById?.attributes?.desc?.[lang]!}</ReactMarkdown>
                </div>
            </div>
        </div>
    )
}

export default MoreInfo
