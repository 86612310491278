import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPhoto, IPhotoTotalInfo } from '../../models/IPhoto'

interface PhotoState {
	rightPhotos: IPhoto[]
	photos: IPhoto[]
	isLoading: boolean
	error: string
	selectedNavPage: string
	pageCount: number
}

const initialState: PhotoState = {
	rightPhotos: [],
	photos: [],
	isLoading: false,
	error: '',
	selectedNavPage: '',
	pageCount: 0,
}

export const photoSlice = createSlice({
	name: 'photos',
	initialState,
	reducers: {
		photosByFilterFetching(state) {
			state.isLoading = true
		},
		photosByFilterFetchingSuccess(
			state,
			action: PayloadAction<IPhotoTotalInfo>
		) {
			if (action.payload.data.length) {
				const arr = action.payload.data
				const evenArray: IPhoto[] = []
				const oddArray: IPhoto[] = []

				for (let i = 0; i < arr.length; i++) {
					if (i % 2 === 0 || i === 0) {
						evenArray.push(arr[i])
					} else {
						oddArray.push(arr[i])
					}
				}

				state.isLoading = false
				state.error = ''
				state.photos = evenArray
				state.pageCount = action.payload.meta.pagination.pageCount
				if (!state.rightPhotos.length) {
					state.rightPhotos = state.rightPhotos.concat(oddArray || [])
				}
			} else {
				state.isLoading = false
				state.error = ''
				state.rightPhotos = []
				state.photos = []
				state.pageCount = action.payload.meta.pagination.pageCount
			}
		},
		photosByFilterFetchingError(state, action: PayloadAction<IPhoto[]>) {
			state.isLoading = false
		},
		photosByPaginationFetching(state) {
			state.isLoading = true
		},
		photosByPaginationFetchingSuccess(
			state,
			action: PayloadAction<IPhotoTotalInfo>
		) {
			if (action.payload.data.length) {
				const arr = action.payload.data
				const evenArray: IPhoto[] = []
				const oddArray: IPhoto[] = []

				for (let i = 0; i < arr.length; i++) {
					if (i % 2 === 0) {
						evenArray.push(arr[i])
					} else {
						oddArray.push(arr[i])
					}
				}

				state.isLoading = false
				state.error = ''
				state.pageCount = action.payload.meta.pagination.pageCount
				if (state.rightPhotos.length && state.photos.length) {
					state.photos = state.photos.concat(evenArray || [])
					state.rightPhotos = state.rightPhotos.concat(oddArray || [])
				} else {
					state.photos = evenArray
					state.rightPhotos = oddArray
				}
			} else {
				state.isLoading = false
				state.error = ''
				state.rightPhotos = []
				state.photos = []
				state.photos = action.payload.data
				state.pageCount = action.payload.meta.pagination.pageCount
			}
		},
		photosByPaginationFetchingError(state, action: PayloadAction<IPhoto[]>) {
			state.isLoading = false
		},
		selectNavPage: (state, action: PayloadAction<string>) => {
			state.selectedNavPage = action.payload
			// state.isLoading = false
		},
		clearState: (state, action: PayloadAction<string>) => {
			state.isLoading = true
			state.photos = []
			state.rightPhotos = []
			state.isLoading = false
		}
	}
})
export default photoSlice.reducer
