import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchBlogs} from "./ActionCreators";
import {IBlogs} from "../../models/IBlogs";

interface BlogsState {
    isLoading: boolean,
    error: string,
    blogs: IBlogs[],
    countBlog: number
}

const initialState: BlogsState = {
    isLoading: false,
    error: '',
    blogs:[],
    countBlog: 0

}
export const blogsSlice = createSlice({
    name: 'blogs',
    initialState,
    reducers: {
        incrementCountBlog: (state) => {
            state.countBlog += 1;
        },

    },
    extraReducers: {
        [fetchBlogs.fulfilled.type]: (state, action: PayloadAction<IBlogs[]>) => {
            state.isLoading = false
            state.error = ''
            state.blogs = action.payload
        },
        [fetchBlogs.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchBlogs.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        }
    }
})

export const { incrementCountBlog } = blogsSlice.actions;

export const selectCountBlog = (state: any) => state.blogs.countBlog;
export default blogsSlice.reducer