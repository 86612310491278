import { useState, useEffect } from 'react';

function useInViewPort<T extends HTMLElement>(
    ref: React.RefObject<T>,
    options?: IntersectionObserverInit,
    onEnterViewport?: () => void,
    delay: number = 200,
    scrollOffset: number = 100
) {
    const [inViewport, setInViewport] = useState(false);
    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                if (timeoutId) clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    if (onEnterViewport) {
                        onEnterViewport();
                        window.scrollBy(0, -scrollOffset);
                    }
                }, delay);
            }
        }, options);

        const currentRef = ref.current;
        if (currentRef) {
            observer.observe(currentRef);
        }
        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [options, ref, onEnterViewport, delay, scrollOffset]);

    return inViewport;
}

export default useInViewPort;
