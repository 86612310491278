import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import PriceInfoBlock from '../../components/PriceInfoBlock'
import PriceInfoStage from '../../components/PriceInfoStage'
import {useAppDispatch, useAppSelector} from '../../hooks/redux'
import {
    fetchRepairStages,
    fetchServicePricesStages, repairDataFetch
} from '../../store/reducers/ActionCreators'
import {IStage} from '../../models/IStages'
import Loader from '../../components/Loader/Loader'
import './index.scss'
import axios from "axios";
import {CONFIG} from "../../config";

const Repair = () => {
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const {stages, error, isLoading} = useAppSelector(
        state => state.stageReducer
    )
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [formRepairData, setFormRepairData] = useState({
        name: '',
        email: '',
        quadrature: '',
        place: '',
        isDesign: false
    });
    const [showModal, setShowModal] = useState(false)
    const priceBlocks = [
        {
            id: 1,
            title: t('Work and materials (1 м²)'),
            price: 500,
            moreInfo: false
        }
    ]
    useEffect(() => {
        dispatch(fetchRepairStages())
    }, [])

    const openModal = () => {
        setShowModal(true)
    }
    const closeModal = () => {
        setShowModal(false)
    }

    const closeSuccessModal = () => {
        setSuccessModalVisible(false)
        setErrorModalVisible(false);
        setFormRepairData({name: "", email: "", quadrature: "", isDesign: false, place: ""})
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Escape') {
            closeModal();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleChange = (e: any) => {
        const { name, value, type, checked } = e.target;
        setFormRepairData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        dispatch(repairDataFetch(formRepairData)).then(
            (res: any) => {
                if (res.payload.status === 200) {
                    setSuccessModalVisible(true)
                } else {
                    setErrorModalVisible(true)
                }
        })
        setShowModal(false)
        setFormRepairData({name: "", email: "", quadrature: "", isDesign: false, place: ""})
    };


    return isLoading ? (
        <div className="loader-price">
            <Loader/>
        </div>
    ) : (
        <div>
            <div style={{margin: "20px 0"}}>
                <h3 className="head-repair">
                    {t(
                        'Turnkey repair services are an individual service and are calculated individually for each project. Prices start from 500 ₼'
                    )}
                </h3>
                <div className="more-info">
                    <button onClick={openModal}>{t("Find out the price of repair")}</button>
                </div>
                {showModal && (
                    <div className="modal-overlay-more">
                        <div className="modal-more-info">
                            <div className="modal-more-info-close-wrap">
                                <img
                                    src={require('../../assets/close_btn.svg').default}
                                    className="modal-more-info-close-wrap__btn"
                                    onClick={closeModal}
                                />
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="modal-more-info-wrapper">
                                    <div
                                        className="modal-more-info-wrapper__head">{t("Write to us about your property")}</div>
                                    <div className="modal-more-info-wrapper__body">
                                        <div className="modal-more-info-wrapper__body-up">
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder={`${t("Name")}`}
                                                value={formRepairData.name}
                                                onChange={handleChange}
                                            />
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder={`${t("Phone or email")}`}
                                                value={formRepairData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="modal-more-info-wrapper__body-up">
                                            <input
                                                type="text"
                                                name="quadrature"
                                                placeholder={`${t("Quadrature of your object")}`}
                                                value={formRepairData.quadrature}
                                                onChange={handleChange}
                                            />
                                            <input
                                                type="text"
                                                name="place"
                                                placeholder={`${t("Where is the?")}`}
                                                value={formRepairData.place}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-more-info__check">
                                    <div className="modal-more-info__check-text">{t("Is there a design for the project?")}</div>
                                    <div className="modal-more-info__check-wrap">
                                        <input
                                            type="checkbox"
                                            name="isDesign"
                                            checked={formRepairData.isDesign}
                                            onChange={() => setFormRepairData(prevState => ({...prevState, isDesign: true}))}
                                        />
                                        <label>{t("Yes")}</label>
                                    </div>
                                    <div className="modal-more-info__check-wrap">
                                        <input
                                            type="checkbox"
                                            name="isDesign"
                                            checked={!formRepairData.isDesign}
                                            onChange={() => setFormRepairData(prevState => ({...prevState, isDesign: false}))}
                                        />
                                        <label>{t("No")}</label>
                                    </div>
                                </div>
                                <div className="modal-more-info-footer">
                                    <button
                                        // disabled={!formRepairData.name || !formRepairData.email}
                                        type="submit"
                                        className="modal-more-info-footer__send"
                                    >{t("Find out the price of repair")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
                {successModalVisible && (
                    <div className="success-modal-overlay">
                        <div className='success-modal-overlay__close-head'>
                            <img
                                className='success-modal-overlay__close-head-img'
                                src={require('../../assets/close_btn.svg').default}
                                onClick={closeSuccessModal}
                            />
                        </div>
                        <div className='success-modal-overlay__text-wrap'>
                            <div>{t("Your application is accepted")}</div>
                            <div>{t("We will contact you shortly")}</div>
                        </div>
                    </div>
                )}
                {errorModalVisible && (
                    <div className="error-modal-overlay">
                        <div className='success-modal-overlay__close-head'>
                            <img
                                className='success-modal-overlay__close-head-img'
                                src={require('../../assets/close_btn.svg').default}
                                onClick={closeSuccessModal}
                            />
                        </div>
                        <div className='error-modal-overlay__text-wrap'>
                            <div style={{ color: "red", background: "white"}}>{t("Something went wrong")}</div>
                        </div>
                    </div>
                )}
                <h2 className="repair-head-text">
                    {t('Repair includes the following stage:')}
                </h2>
            </div>
            <div className='stages-wrapper'>
                {stages.map((stage: IStage) => (
                    <PriceInfoStage key={stage.id} stage={stage}/>
                ))}
            </div>
        </div>
    )
}

export default Repair
