import React, {useState} from 'react';
import "./index.scss"
import Navigation from "../Navigation/Navigation";
import Loader from "../Loader/Loader";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {useAppSelector} from "../../hooks/redux";
import {NavLink} from "react-router-dom";

const NotFound = () => {
    const [isLoading, setIsLoading] = useState(false)
    const {t} = useTranslation('translation', {i18n});
    const {burgerMenu} = useAppSelector(state => state.burgerMenuReducer)
    return (
        <>
            <Navigation/>
            {isLoading ? (
                <Loader/>
            ) : (
                <div className='not-found'>
                    <div className='not-found__wrap'>
                        <div className='not-found__wrap-info'>
                            <h2 className='not-found__wrap-info-name'>
                                {'Упс что то пошло не так'}
                            </h2>
                            <div className='not-found__wrap-info-back'>
                                <NavLink to={`/${i18n.language}/`}
                                         className="not-found__wrap-info-back-text">{'Давайте начнем сначала'}</NavLink>
                            </div>
                            <div className="not-found__wrap-info-image">
                                <img src={require("../../assets/kotik 404.jpg")}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default NotFound;