import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone, faXmark} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faTelegram, faWhatsapp, faYoutube, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {useAppSelector} from "../../hooks/redux";

const FooterCall = () => {
    const {blog} = useAppSelector(state => state.blogReducer)
    const location = useLocation()
    const {t} = useTranslation()
    const [showCall, setShowCall] = useState(false)
    const [showButton, setShowButton] = useState(true);
    const [iconIndex, setIconIndex] = useState(0);
    const {lang} = useAppSelector(state => state.settingsReducer)
    const icons = [faXmark, faFacebook,faInstagram, faTelegram, faWhatsapp, faPhone, faEnvelope];
    const phoneNumber = '+994 50 220 65 65';
    const emailm = 'Akozlova@riberry.az';
    const email = 'info@riberry.az'
    const isLang: boolean = lang === "en"

    let originalPath = location.pathname;
    let modifiedPath = originalPath.replace(/\/(en|az|ru)\//, '');
    const handlePhoneClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };
    const handleEmailClick = (event: any) => {
        event.preventDefault();
        window.location.href = `mailto:${email}`;
    };

    useEffect(() => {
        const handleScroll = () => {
            const lastOffset = document.documentElement.scrollHeight - window.innerHeight - 200;
            setShowButton(window.scrollY < lastOffset);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        let interval: any;
        if (!showCall) {
            interval = setInterval(() => {
                setIconIndex((prevIndex: any) => (prevIndex + 1) % icons.length);
            }, 2000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [showCall]);

    return (
        <>
            <div className={`footer-call ${showCall ? 'show' : ''}`}
                 style={{
                     bottom: showButton ? 20 : (showCall ? 210 : (window.innerWidth < 1025 ? 20 : 210))
                 }}
                 onClick={() => setShowCall(!showCall)}>
                {!showCall ? (iconIndex === 0 ? <span className="comun-text">{t("Contact")}</span> :
                    <FontAwesomeIcon icon={icons[iconIndex]}/>) : <FontAwesomeIcon icon={faXmark}/>}
            </div>

            {showCall ? (
                <div className={`footer-call-item ${showCall ? 'show' : ''}`}
                     style={{
                         bottom: showButton ? 20 : (showCall ? 210 : (window.innerWidth < 1025 ? 20 : 210))
                     }}>
                    <a target="_blank" rel="nofollow" href='https://www.facebook.com/Riberry'
                       className="footer-call-item-wrap icon-wrap">
                        <FontAwesomeIcon icon={faFacebook}/>
                        <p className='info-title-vk'>Facebook</p>
                    </a>
                    <a target="_blank" rel="nofollow" href='https://www.instagram.com/riberry_design/'
                       className="footer-call-item-wrap icon-wrap">
                        <FontAwesomeIcon icon={faInstagram}/>
                        <p className='info-title-vk'>instagram</p>
                    </a>
                    <a target="_blank" rel="nofollow" href='https://www.youtube.com/channel/UC5qZX_RxVmIFDxFJ_bylnlA'
                       className="footer-call-item-wrap icon-wrap">
                        <FontAwesomeIcon icon={faYoutube}/>
                        <p className='info-title-vk'>Youtube</p>
                    </a>
                    <a target="_blank" rel="nofollow" href="https://t.me/riberry_design"
                       className="footer-call-item-wrap icon-wrap">
                        <FontAwesomeIcon icon={faTelegram}/>
                        <p className='info-title-tg'>{t('Telegram')}</p>
                    </a>
                    <a target="_blank" rel="nofollow"
                       href="https://api.whatsapp.com/send/?phone=%2B994552206565&text&type=phone_number&app_absent=0"
                       className="footer-call-item-wrap icon-wrap">
                        <FontAwesomeIcon icon={faWhatsapp}/>
                        <p className='info-title-wp'>{t("WhatsApp")}</p>
                    </a>
                    <div onClick={handlePhoneClick} className="footer-call-item-wrap-tel icon-wrap">
                        <FontAwesomeIcon icon={faPhone}/>
                        <p className='info-title'>{phoneNumber}</p>
                    </div>
                    <a onClick={handleEmailClick} href={`mailto:${emailm}`} rel="nofollow"
                       className="footer-call-item-wrap-mail icon-wrap">
                        <FontAwesomeIcon icon={faEnvelope}/>
                        <p className='info-title-mail'>{t('Mail')}</p>
                    </a>
                </div>
            ) : (
                <div className="footer-call-item-block"></div>
            )}
            {showCall ? (
                    <div className={`footer-call-right-info ${showCall ? 'show' : ''}`}
                         style={{
                             padding: isLang ? "2px 15px 3px 45px": "10px 15px 10px 45px",
                             bottom:  showButton ? 20 : (showCall ? 210 : (window.innerWidth < 1025 ? 20 : 210))
                         }}>
                        {t('Choose a convenient method of communication')}
                    </div>
                )
                :
                <div className="footer-call-item-block"></div>
            }
        </>

    );
};

export default React.memo(FooterCall);
