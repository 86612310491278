import React, {useState, useEffect} from 'react'
import './i18n'
import {Routes, Route, useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import Home from './pages/home/index'
import Header from './components/Header/Header'
import { Helmet } from 'react-helmet';
import AboutUs from './pages/aboutUs'
import ServicesPrices from './pages/servicesPricesTabs'
import Blogs from './pages/blogs'
import Footer from './components/Footer'
import MoreInfo from './pages/moreInfo'
import Contacts from './pages/contacts'
import Blog from './pages/blog'
import SliderComponent from './utils/slider'
import {useAppDispatch, useAppSelector} from './hooks/redux'
import {
    clearPhotoState, deleteStyleAction, deleteTypeAction,
    openFilterMenu,
    openSelectedFilter,
} from './store/reducers/ActionCreators'
import FilterComponent from "./components/FilterComponent/FilterComponent";
import useWindowDimensions from "./hooks/useWindowDimensions";
import TermsOfUse from "./components/TermsOfUse/TermsOfUse";
import NotFound from "./components/NotFound/NotFound";
import QuestionComponent from "./components/Question/QuestionComponent";
import FooterCall from "./components/FooterCall/FooterCall";
import FooterBtnUp from "./components/FooterBtnUp/FooterBtnUp";
import './App.scss'


const skeletonSliderArr = [1, 2, 3, 4, 5, 6]
const skeletonSliderArr1 = [1, 2, 3, 4, 5]
const skeletonSliderArr2 = [1, 2, 3, 4]
const skeletonSliderArr3 = [1, 2, 3]
const skeletonSliderArr4 = [1, 2]
const skeletonSliderArr5 = [1]

function App() {
    const {t, i18n} = useTranslation()
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const [sliderItems, setSliderItems] = useState<any>([])
    const [showHeader, setShowHeader] = useState<boolean>(true)
    const {photos, error, isLoading, selectedNavPage} = useAppSelector(
        state => state.photoReducer
    )

    const {blog} = useAppSelector(state => state.blogReducer)
    const {burgerMenu, showFilter} = useAppSelector(
        state => state.burgerMenuReducer
    )
    const {selectedStyle, selectedType} = useAppSelector(
        state => state.filterReducer
    )

    const [currentLang, setCurrentLang] = useState('ru');

    const updateLanguage = () => {
        const pathSegments = window.location.pathname.split('/');
        const langFromPath = pathSegments[1] || 'ru';
        setCurrentLang(langFromPath);
        if (langFromPath !== window.location.pathname.split("/")[1]) {
            i18n.changeLanguage(langFromPath);
        }
    };

    useEffect(() => {
        updateLanguage();
    }, [location.pathname]);

    useEffect(() => {
        document.documentElement.lang = currentLang;
    }, [currentLang]);

    useEffect(() => {
        if (window.location.pathname === '/') {
            const userLang = navigator.language.split('-')[0];
            window.location.replace(`/${userLang}/`);
        }
        // dispatch(photosByPagination([], [], '', 1))
    }, [])

    useEffect(() => {
        let ourProjects = [
            ...photos
                ?.map(item => item?.attributes?.photos)
                .map(p => p.map(i => i.photo?.data?.attributes?.url))
        ]
        let sliderItems = [].concat.apply(
            [],
            ourProjects.map((i: any) => i)
        )
        setSliderItems(sliderItems)
    }, [photos])

    useEffect(() => {
        const currentPath = window.location.pathname;
        const correctedPath = currentPath.replace(/\/{2,}/g, '/') + '';

        if (currentPath !== correctedPath) {
            navigate(correctedPath, {replace: true});
        }
    }, [navigate]);

    const closeFilter = (id: any) => {
        setShowHeader(true);
        dispatch(openSelectedFilter(false));
        dispatch(openFilterMenu(false));
        dispatch(clearPhotoState());

        const itemToRemoveType = selectedType[id - 1];
        dispatch(deleteTypeAction(itemToRemoveType || ""));

        const itemToRemoveStyle = selectedStyle[id - 1];
        dispatch(deleteStyleAction(itemToRemoveStyle || ""));
    }

    const newArrayType = (selectedType)?.map((item, index) => {
        return {
            id: index + 1,
            value: item
        };
    });
    const newArrayStyle = (selectedStyle)?.map((item, index) => {
        return {
            id: index + 1,
            value: item
        };
    });

    let originalPath = location.pathname;
    let modifiedPath = originalPath.replace(/\/(en|az|ru)\//, '');
    const pathNameLocal = window.location.pathname.replace(`/${i18n.language}`, `/${localStorage.getItem("lang")}`)
    return (
        <div>
            {showHeader ?
                <Header setShowHeader={setShowHeader}/>
                :
                <FilterComponent setShowHeader={setShowHeader}/>
            }
            <div className="header-filter">

                {showFilter ? null :
                    ((newArrayType)?.map((item) => (
                        <div
                            className="header-filter_item"
                            onClick={() => closeFilter(item.id)}
                            key={item.id}
                        >
                            <div>{t(item.value)}<img src={require('./assets/close_btn.svg').default} alt='Photo'/></div>
                        </div>
                    )))}
                {showFilter ? null :
                    (newArrayStyle?.map((item) => (
                        <div
                            className="header-filter_item"
                            onClick={() => closeFilter(item.id)}
                            key={item.id}
                        >
                            <div>{t(item.value)}<img src={require('./assets/close_btn.svg').default} alt='Photo'/></div>
                        </div>
                    )))}
            </div>

            <div className='main-wrapper'>
                <Routes>
                    <Route path="/:language/" element={<Home/>}/>
                    <Route path='/:language/interior' element={<Home/>}/>
                    <Route path='/:language/public' element={<Home/>}/>
                    <Route path='/:language/landscape' element={<Home/>}/>
                    <Route path='/:language/realization' element={<Home/>}/>
                    <Route path="/:language/about-us/" element={<AboutUs/>}/>
                    <Route path="about-us/" element={<AboutUs/>}/>
                    <Route path="/:language/services-prices/" element={<ServicesPrices/>}/>
                    <Route path="services-prices/" element={<ServicesPrices/>}/>
                    <Route path="/:language/blogs/" element={<Blogs/>}/>
                    <Route path="blogs/" element={<Blogs/>}/>
                    <Route path="/:language/blogs/:id" element={<Blog/>}/>
                    <Route path="blogs/:id" element={<Blog/>}/>
                    <Route path="/:language/moreInfo/:id" element={<MoreInfo/>}/>
                    <Route path="/:language/interior/moreInfo/:id" element={<MoreInfo/>}/>
                    <Route path="/:language/public/moreInfo/:id" element={<MoreInfo/>}/>
                    <Route path="/:language/landscape/moreInfo/:id" element={<MoreInfo/>}/>
                    <Route path="/:language/realization/moreInfo/:id" element={<MoreInfo/>}/>
                    <Route path="moreInfo/:id" element={<MoreInfo/>}/>
                    <Route path="/:language/contacts/" element={<Contacts/>}/>
                    <Route path="contacts/" element={<Contacts/>}/>
                    <Route path="/:language/terms-of-use/" element={<TermsOfUse/>}/>
                    <Route path="terms-of-use/" element={<TermsOfUse/>}/>
                    <Route path="/:language/question/" element={<QuestionComponent/>}/>
                    <Route path="question/" element={<QuestionComponent/>}/>
                    <Route path="/:language/*" element={<NotFound/>}/>
                </Routes>

                <div className="footer-call-wrapper">
                    <div className="footer-wrap-call">
                        <FooterCall/>
                    </div>
                    <FooterBtnUp/>
                </div>
            </div>

            {
                pathNameLocal === `/${i18n.language}/contacts/` ? <div>
                        <iframe
                            className='maps'
                            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d326.30001439847484!2d49.85746493805557!3d40.41355381113214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x403087ff2a64c273%3A0xe1c2b5cd66bff72c!2sMinera%20MMC!5e0!3m2!1sen!2s!4v1655457356004!5m2!1sen!2s'
                            width='98%'
                            height='450'
                            style={{border: 0, width: "100%"}}
                            allowFullScreen={true}
                            loading='lazy'
                            referrerPolicy='no-referrer-when-downgrade'
                        ></iframe>
                        <div style={{marginTop: "50px"}}>
                            {sliderItems.length ? (
                                <div className='slider__wrapper'>
                                    <SliderComponent sliderItems={sliderItems}/>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    :
                    <div></div>
            }
             <Footer/>
        </div>
    )
}

export default App
