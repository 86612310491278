import React, {useEffect, useState} from 'react'
import {NavLink, useLocation, useNavigate, useParams} from 'react-router-dom'
import {
    exteriorPlaceStyle,
    interiorPlaceStyle,
    interiorPlaceType,
    // landscapePlaceStyle,
    landscapePlaceType,
    // publicPlaceStyle,
    publicPlaceType,
    // realizationPlaceStyle,
    realizationPlaceType
} from '../../utils/enum'
import {useTranslation} from 'react-i18next'
import {useAppDispatch, useAppSelector} from '../../hooks/redux'
import {
    addStyleAction,
    addTypeAction,
    clearAllStylesAction,
    clearAllTypesAction,
    clearPhotoState,
    deleteStyleAction,
    deleteTypeAction,
    openBurgerMenu,
    openFilterMenu,
    openSelectedFilter, selectNavPage
} from '../../store/reducers/ActionCreators'
import './Navigation.scss'

const logo = require('../../assets/logo.svg').default
const type__filter = require('../../assets/type__filter.svg').default
const style__filter = require('../../assets/style.svg').default
const arrow__down = require('../../assets/arrow-down.png')
const up__down = require('../../assets/triangular-filled-up-arrow.svg').default

interface NavigationProps {
    showAllStyleFilters?: boolean
    showAllTypeFilters?: boolean
    setShowAllStyleFilters?: (item: boolean) => void
    setShowAllTypeFilters?: (item: boolean) => void
    setPage?: (i: number) => void
    selectedNav?: number
    setSelectedNav?: any
    setShowTypes?: any
    showTypes?: any
    setShowStyles?: any
    showStyles?: any
}

const Navigation = ({
                        showAllStyleFilters = false,
                        showAllTypeFilters = false,
                        setShowAllTypeFilters = () => null,
                        setShowAllStyleFilters = () => null,
                        setShowTypes = () => null,
                        showTypes = false,
                        setShowStyles = () => null,
                        showStyles = false,
                        selectedNav = 1,
                        setSelectedNav = () => null,


                    }: NavigationProps) => {
    const {t, i18n} = useTranslation()
    const dispatch = useAppDispatch()
    const {burgerMenu, showFilter} = useAppSelector(
        state => state.burgerMenuReducer
    )
    const {blog, isLoading} = useAppSelector(state => state.blogReducer)
    const {selectedStyle, selectedType} = useAppSelector(
        state => state.filterReducer
    )

    const location = useLocation()
    const navigate = useNavigate();

    const langPrefix = location.pathname.startsWith(`/${i18n.language}`) ? `/${i18n.language}` : '';

    const showFilterPath = [`/${i18n.language}/`, `${langPrefix}/interior/`, `${langPrefix}/public/`, `${langPrefix}/landscape/`, `${langPrefix}/realization/`]

    useEffect(() => {
        const currentPath = location.pathname;
        const hasTrailingSlash = currentPath.endsWith('/');
        if (!hasTrailingSlash && currentPath !== `/${i18n.language}/blogs/${blog.id}`) {
            navigate(`${currentPath}`, { replace: true });
        }
    }, [location.pathname, navigate]);

    const leftNav = [
        { id: '1', title: t('Portfolio'), path: `/${i18n.language}/` },
        { id: '2', title: t('About'), path: `${langPrefix}/about-us/` },
        { id: '3', title: t('Services and prices'), path: `${langPrefix}/services-prices/` },
        { id: '4', title: t('Blog'), path: `${langPrefix}/blogs/` },
        { id: '6', title: t('Question'), path: `${langPrefix}/question/` },
        { id: '5', title: t('Contacts'), path: `${langPrefix}/contacts/` },
    ];

    const handleChange = (event: any, type: string) => {
        dispatch(clearPhotoState())

        const {target: {value}} = event

        if (type === 'selectedType') {
            if (selectedType.find((item: string) => item === value)) {
                dispatch(deleteTypeAction(value))
            } else {
                dispatch(addTypeAction(value))
                setShowAllTypeFilters(false)
                setShowAllStyleFilters(false)
            }
        } else {
            if (selectedStyle.find((item: string) => item === value)) {
                dispatch(deleteStyleAction(value))
            } else {
                dispatch(addStyleAction(value))
                setShowAllTypeFilters(false)
                setShowAllStyleFilters(false)
            }
        }

        const correctPath = location.pathname;

        let parts = correctPath.split('/').filter(part => part !== '');
        if (parts.length === 1 && parts[0] === `${i18n.language}`) {
            parts.push('interior');
        } else {
            parts = parts.filter(part => part !== `${i18n.language}`);
        }
        const resultPath = parts[parts.length - 1];

        // navigate(`/${i18n.language}/${resultPath}/?type=${correctedValue}/`);

    }

    const handleChangeStyle = (event: any, type: string) => {
        dispatch(clearPhotoState())

        const {target: {value}} = event
        if (type === 'selectedType') {
            if (selectedType.find((item: string) => item === value)) {
                dispatch(deleteTypeAction(value))
            } else {
                dispatch(addTypeAction(value))
                setShowAllTypeFilters(false)
                setShowAllStyleFilters(false)
            }
        } else {
            if (selectedStyle.find((item: string) => item === value)) {
                dispatch(deleteStyleAction(value))
            } else {
                dispatch(addStyleAction(value))
                setShowAllTypeFilters(false)
                setShowAllStyleFilters(false)
            }
        }

        const correctPath = location.pathname;

        let parts = correctPath.split('/').filter(part => part !== '');
        if (parts.length === 1 && parts[0] === `${i18n.language}`) {
            parts.push('interior');
        } else {
            parts = parts.filter(part => part !== `${i18n.language}`);
        }
        const resultPath = parts[parts.length - 1];

        // navigate(`/${i18n.language}/${resultPath}/?style=${value}/`);
    }

    useEffect(() => {

        const correctPath = location.pathname;

        let parts = correctPath.split('/').filter(part => part !== '');
        if (parts.length === 1 && parts[0] === `${i18n.language}`) {
            parts.push('interior');
        } else {
            parts = parts.filter(part => part !== `${i18n.language}`);
        }
        const resultPath = parts[parts.length - 1];
        const correctedValue = encodeURIComponent(selectedType[0]).replace(/%20/g, '-')
        const correctedStyleValue = encodeURIComponent(selectedStyle[0]).replace(/%20/g, '-')

        if (selectedType.length > 0 && selectedStyle.length > 0) {
            navigate(`/${i18n.language}/${resultPath}/?${correctedValue}-${correctedStyleValue}/`);
        } else if (selectedType.length > 0) {
            navigate(`/${i18n.language}/${resultPath}/?${correctedValue}/`);
        } else if (selectedStyle.length > 0) {
            navigate(`/${i18n.language}/${resultPath}/?${correctedStyleValue}/`);
        }

    },[selectedType, selectedStyle])

    const handleChangeShowTypes = () => {
        setShowTypes(!showTypes)
        setShowAllTypeFilters(false)
        setSelectedNav(1)
    }
    const handleChangeShowStyle = () => {
        setShowStyles(!showStyles)
        setShowAllStyleFilters(false)
        setSelectedNav(2)
    }
    const placeTypeByPath = (pathname: any, mobile?: boolean) => {
        let typesForShow: any = []

        if (pathname === `/${i18n.language}/`) {
            typesForShow = [...interiorPlaceType]
        } else if (pathname === `/${i18n.language}/public/`) {
            typesForShow = [...publicPlaceType]
        } else if (pathname === `/${i18n.language}/landscape/`) {
            typesForShow = [...landscapePlaceType]
        } else if (pathname === `/${i18n.language}/interior/`) {
            typesForShow = [...interiorPlaceType]
        } else {
            typesForShow = [...realizationPlaceType]
        }

        const selectedElements = typesForShow.filter((item: any) => selectedType.includes(item.value));
        const unselectedElements = typesForShow.filter((item: any) => !selectedType.includes(item.value));
        const reorderedArray = selectedElements.concat(unselectedElements);

        return mobile ? (
            <>
                {reorderedArray.map(
                    (type: { id: number; value: string; title?: string }) => (
                        <div
                            onClick={() => {
                                handleChange({target: {value: type.value}}, 'selectedType');
                                setShowTypes(true)
                            }}
                            className={
                                !!selectedType.find(item => item === type.value)
                                    ? 'mobile-filter-selected'
                                    : 'mobile-filter'
                            }
                            key={type.id}
                        >
                            {t(`${type.title || type.value}`)}
                        </div>
                    )
                )}
            </>
        ) : (
            <>
                {reorderedArray.map(
                    (type: { id: number; value: string; title?: string }) => (
                        <li key={type.id} style={{minHeight: location.pathname === `/${i18n.language}/landscape/` ? "53px" : "38px"}}>
                            <input
                                id={`checkbox${type.id}`}
                                value={type.value}
                                onChange={e => {
                                    handleChange(e, 'selectedType');
                                    // handleTypeClick(type.value)
                                }}
                                checked={showTypes && !!selectedType.find(item => item === type.value)}
                                type='checkbox'
                            />
                            <label htmlFor={`checkbox${type.id}`}/>
                            {t(`${type.title || type.value}`)}
                        </li>
                    )
                )}
            </>
        )
    }

    const placeStyleByPath = (pathname: any, mobile?: boolean) => {
        let stylesForShow: any = []
        if (pathname === `/${i18n.language}/`) {
            stylesForShow = [...interiorPlaceStyle]
        } else if (pathname === `/${i18n.language}/public/`) {
            stylesForShow = [...interiorPlaceStyle]
        } else if (pathname === `/${i18n.language}/landscape/`) {
            stylesForShow = [...exteriorPlaceStyle]
        } else {
            stylesForShow = [...interiorPlaceStyle]
        }

        const selectedElements = stylesForShow.filter((item: any) => selectedStyle.includes(item.value));
        const unselectedElements = stylesForShow.filter((item: any) => !selectedStyle.includes(item.value));
        const reorderedArrayStyle = selectedElements.concat(unselectedElements);

        return mobile ? (
            <>
                {reorderedArrayStyle.map(
                    (type: { id: number; value: string; title?: string }) => (
                        <div
                            onClick={() => {
                                handleChangeStyle({target: {value: type.value}}, 'selectedStyle');
                            }}
                            className={
                                !!selectedStyle.find(item => item === type.value)
                                    ? 'mobile-filter-selected'
                                    : 'mobile-filter'
                            }
                            key={type.id}
                        >
                            {t(`${type.title || type.value}`)}

                        </div>
                    )
                )}
            </>
        ) : (
            <>
                {reorderedArrayStyle.map(
                    (type: { id: number; value: string; title?: string }) => {
                        return (
                            <li key={type.id}>
                                <input
                                    id={`checkboxStyle${type.id}`}
                                    value={type.value}
                                    onChange={e => handleChangeStyle(e, 'selectedStyle')}
                                    checked={!!selectedStyle.find(item => item === type.value)}
                                    type='checkbox'
                                />
                                <label htmlFor={`checkboxStyle${type.id}`}/>
                                {t(`${type.title || type.value}`)}
                            </li>
                        )

                    }
                )}
            </>
        )
    }

    // const pathNameLocal = window.location.pathname.replace(`/${i18n.language}`, `/${localStorage.getItem("lang")}`)
    const pathNameLocal = window.location.pathname.replace(
        `/${i18n.language}`,
        `/${localStorage.getItem("lang") || i18n.language}`
    );
    return (
        <>
            <div
                style={{display: !showFilter && burgerMenu ? 'block' : 'none'}}
                className='navigation-left'
            >
                <ul>
                    {leftNav.map(item => {
                        return (
                        <NavLink
                            onClick={() => {
                                if (item.path === `/${i18n.language}/`)
                                    dispatch(selectNavPage(`/${i18n.language}/`))
                                else dispatch(selectNavPage(item.path))
                                // setIsActive(item.id)
                                dispatch(openBurgerMenu(false))
                                dispatch(clearAllTypesAction())
                                dispatch(clearAllStylesAction())
                                dispatch(clearPhotoState())
                            }}
                            className={
                                `${pathNameLocal}` === item.path
                                    ? 'navigation-left__item selected'
                                    : 'navigation-left__item '
                            }
                            key={item.id}
                            to={item.path}
                        >
                            {item.title}
                        </NavLink>
                        )
                    })}
                </ul>
                {showFilterPath.find((path) => (
                    path === window.location.pathname)
                ) && (
                    <div className='filter-block'>
                        <div
                            onClick={handleChangeShowTypes}
                            className='filter-place-type__title'
                        >
                            <img width={24} height={24} src={type__filter} alt='type-filter'/>
                            <div>{t('Place type')}</div>
                            <img width={10} height={10} src={showTypes ? up__down : arrow__down} alt='down'/>
                        </div>
                        {showTypes && (
                            <ul
                                style={
                                    showAllTypeFilters
                                        ? {height: '100%'}
                                        : {maxHeight: '150px'}
                                }
                                className='filter-place-type'
                            >
                                {selectedType.length > 0 && (
                                    <div
                                        onClick={() => {
                                            dispatch(clearAllTypesAction())
                                            dispatch(clearPhotoState())
                                            // setShowTypes(false)
                                        }}
                                        className='filter-place-type__cancel'
                                    >
                                        <u style={{fontSize: "14px"}}>{t('Cancel All')}</u>
                                    </div>
                                )}
                                {location && placeTypeByPath(location?.pathname)}
                            </ul>
                        )}
                        {!showAllTypeFilters && showTypes && (
                            <button
                                className='show-all-filter-btn'
                                onClick={() => setShowAllTypeFilters(true)}
                            >
                                <u style={{fontSize: "14px"}}>{t('More')}</u>
                            </button>
                        )}
                        <div
                            onClick={handleChangeShowStyle}
                            className='filter-place-type__title'
                        >
                            <img width={24} height={24} src={style__filter} alt='style-filter'/>
                            <div>{t('Place style')}</div>
                            <img width={10} height={10} src={showStyles ? up__down : arrow__down} alt='down'/>
                        </div>
                        {showStyles && (
                            <ul
                                style={
                                    showAllStyleFilters
                                        ? {height: '100%'}
                                        : {maxHeight: '150px'}
                                }
                                className='filter-place-type'
                            >
                                {selectedStyle.length > 0 && (
                                    <div
                                        onClick={() => {
                                            dispatch(clearAllStylesAction())
                                            dispatch(clearPhotoState())
                                            setShowStyles(false)
                                        }}
                                        className='filter-place-type__cancel'
                                    >
                                        <u style={{fontSize: "14px"}}>{t('Cancel All')}</u>
                                    </div>
                                )}
                                {location && placeStyleByPath(location?.pathname)}
                            </ul>
                        )}

                        {!showAllStyleFilters && showStyles && (
                            <button
                                className='show-all-filter-btn'
                                onClick={() => setShowAllStyleFilters(true)}
                            >
                                <u style={{fontSize: "14px"}}>{t('More')}</u>
                            </button>
                        )}
                    </div>
                )}
            </div>
            {showFilter && !burgerMenu && (
                <div className='navigation-left mobile'>
                    <div className='Tabs'>
                        <ul className='nav filter-tabs'>
                            <li onClick={() => setSelectedNav(1)}>
                                <div
                                    onClick={handleChangeShowTypes}
                                    className='filter-place-type__title'
                                >
                                    <img src={type__filter} alt='type-filter'/>
                                    <div
                                        style={{
                                            borderBottom: selectedNav === 1 ? '1px solid white' : ''
                                        }}
                                    >
                                        {t('Place type')}
                                    </div>
                                    <img width={10} src={arrow__down} alt='down'/>
                                </div>
                            </li>
                            <li onClick={() => setSelectedNav(2)}>
                                <div
                                    onClick={handleChangeShowStyle}
                                    className='filter-place-type__title'
                                >
                                    <img src={style__filter} alt='style-filter'/>
                                    <div
                                        style={{
                                            borderBottom: selectedNav === 2 ? '1px solid white' : ''
                                        }}
                                    >
                                        {t('Place style')}
                                    </div>
                                    <img width={10} src={arrow__down} alt='down'/>
                                </div>
                            </li>
                        </ul>
                        <div className='outlet'>
                            {selectedNav === 1 && (
                                <div className='mobile-filter__wrapper'>
                                    {location && placeTypeByPath(location.pathname, true)}
                                </div>
                            )}
                            {selectedNav === 2 && (
                                <div className='mobile-filter__wrapper'>
                                    {location && placeStyleByPath(location.pathname, true)}
                                </div>
                            )}
                            <div
                                onClick={() => {
                                    dispatch(openFilterMenu(false))
                                    dispatch(openSelectedFilter(true))
                                }}
                                className='mobile-filter__wrapper__btn-apply'
                            >
                                {t('Apply')}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Navigation
