import { Photo } from '../models/IPhoto'

export const findItemByOrder = (photos: Photo[]) => {
	let items: Photo[] = [...photos].sort((a, b) => a.order - b.order).slice(0, 3)
	let urls: string[] = items.map(
		item => item.photo?.data?.attributes?.formats?.medium.url ?? ''
	)
	return {
		firstItem: items[0],
		secondItem: items[1],
		thirdItem: items[2],
		firstItemUrl: urls[0],
		secondItemUrl: urls[1],
		thirdItemUrl: urls[2]
	}
}
