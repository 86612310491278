import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface FilterState {
	selectedType: string[]
	selectedStyle: string[] 
	selectedNavTp: number
}

const initialState: FilterState = {
	selectedType: [],
	selectedStyle: [],
	selectedNavTp: 0
}
export const filterSlice = createSlice({
	name: 'filter',
	initialState,
	reducers: {
		addType: (state, action: PayloadAction<string>) => {
			// state.selectedType = [...state.selectedType, action.payload]
			const newType = action.payload;
			if (!state.selectedType.includes(newType)) {
				state.selectedType = [newType];
			}
		},
		addStyle: (state, action: PayloadAction<string>) => {
			// state.selectedStyle = [...state.selectedStyle, action.payload]
			const newStyle = action.payload;
			if (!state.selectedStyle.includes(newStyle)) {
				state.selectedStyle = [newStyle];
			}
		},
		deleteType: (state, action: PayloadAction<string>) => {
			// state.selectedType = state.selectedType.filter(
			// 	(item: string) => item !== action.payload
			// )
			const valueToRemove = action.payload;
			state.selectedType = state.selectedType.filter(item => item !== valueToRemove);
			state.selectedStyle = state.selectedStyle.filter(item => item !== valueToRemove);
		},
		deleteStyle: (state, action: PayloadAction<string>) => {
			state.selectedStyle = state.selectedStyle.filter(
				(item: string) => item !== action.payload
			)
		},
		clearAllStyles: (state, action: PayloadAction<[]>) => {
			state.selectedStyle = []
		},
		clearAllTypes: (state, action: PayloadAction<[]>) => {
			state.selectedType = []
		}
	}
})
export default filterSlice.reducer
