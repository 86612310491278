import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	fetchArchStages,
	fetchRepairStages,
	fetchServicePricesStages
} from './ActionCreators'
import { IStage } from '../../models/IStages'
import {photoAttrib} from "../../models/IPhoto";

interface PhotoState {
	stages: IStage[]
	isLoading: boolean
	error: string
}

interface Formats {
	small: photoAttrib
	large: photoAttrib
	thumbnail: photoAttrib
	medium: photoAttrib
}

const initialState: PhotoState = {
	stages: [
		{
			id: null,
			attributes: {
				stage: '0',
				stage_image: {
					data: {
						id: '',
						attributes: {
							name: '',
							width: 0,
							height: 0,
							url: '',
							formats: {
								small: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								},
								large: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								},
								thumbnail: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								}
							}
						}
					}
				},
				stage_desc: {
					az: '',
					ru: '',
					en: '',
					id: 0
				},
				stage_title: {
					az: '',
					ru: '',
					en: '',
					id: 0
				}
			}
		}
	],
	isLoading: false,
	error: ''
}
export const stageSlice = createSlice({
	name: 'stage',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchServicePricesStages.fulfilled.type]: (
			state,
			action: PayloadAction<IStage[]>
		) => {
			state.isLoading = false
			state.error = ''
			state.stages = action.payload
		},
		[fetchServicePricesStages.pending.type]: state => {
			state.isLoading = true
		},
		[fetchServicePricesStages.rejected.type]: (
			state,
			action: PayloadAction<string>
		) => {
			state.isLoading = false
			state.error = action.payload
		},
		[fetchArchStages.fulfilled.type]: (
			state,
			action: PayloadAction<IStage[]>
		) => {
			state.isLoading = false
			state.error = ''
			state.stages = action.payload
		},
		[fetchArchStages.pending.type]: state => {
			state.isLoading = true
		},
		[fetchArchStages.rejected.type]: (state, action: PayloadAction<string>) => {
			state.isLoading = false
			state.error = action.payload
		},
		[fetchRepairStages.fulfilled.type]: (
			state,
			action: PayloadAction<IStage[]>
		) => {
			state.isLoading = false
			state.error = ''
			state.stages = action.payload
		},
		[fetchRepairStages.pending.type]: state => {
			state.isLoading = true
		},
		[fetchRepairStages.rejected.type]: (
			state,
			action: PayloadAction<string>
		) => {
			state.isLoading = false
			state.error = action.payload
		}
	}
})
export default stageSlice.reducer
