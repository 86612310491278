import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchInfoAboutUs} from "./ActionCreators";
import {IAboutUs} from "../../models/IAboutUs";
import {photoAttrib} from "../../models/IPhoto";

interface AboutUsState {
    isLoading: boolean,
    error: string,
    info: IAboutUs
}

interface Formats {
    small: photoAttrib
    large: photoAttrib
    thumbnail: photoAttrib
    medium: photoAttrib
}

const initialState: AboutUsState = {
    isLoading: false,
    error: '',
    info: {
        id: null,
        attributes: {
            title: '',
            description: '',
            title2: '',
            name: ' ',
            text: ' ',
            personInfo: ' ',
            personPhoto: {
                data: [{
                    // @ts-ignore
                    id: 0,
                    attributes: {
                        name: '',
                        width: 0,
                        height: 0,
                        url: '',
                        formats: {
                            small: {
                                name: '',
                                alternativeText: '',
                                caption: '',
                                width: 0,
                                height: 0,
                                formats: {} as Formats,
                                hash: '',
                                ext: '',
                                mime: '',
                                size: 0,
                                url: '',
                                provider: '',
                                createdAt: new Date(),
                                updatedAt: new Date(),
                            },
                            large: {
                                name: '',
                                alternativeText: '',
                                caption: '',
                                width: 0,
                                height: 0,
                                formats: {} as Formats,
                                hash: '',
                                ext: '',
                                mime: '',
                                size: 0,
                                url: '',
                                provider: '',
                                createdAt: new Date(),
                                updatedAt: new Date(),
                            },
                            thumbnail: {
                                name: '',
                                alternativeText: '',
                                caption: '',
                                width: 0,
                                height: 0,
                                formats: {} as Formats,
                                hash: '',
                                ext: '',
                                mime: '',
                                size: 0,
                                url: '',
                                provider: '',
                                createdAt: new Date(),
                                updatedAt: new Date(),
                            }
                        }
                    }
                }]
            },
            images: {
                data: [{
                    // @ts-ignore
                    id: 0,
                    attributes: {
                        name: '',
                        width: 0,
                        height: 0,
                        url: '',
                        formats: {
                            small: {
                                name: '',
                                alternativeText: '',
                                caption: '',
                                width: 0,
                                height: 0,
                                formats: {} as Formats,
                                hash: '',
                                ext: '',
                                mime: '',
                                size: 0,
                                url: '',
                                provider: '',
                                createdAt: new Date(),
                                updatedAt: new Date(),
                            },
                            large: {
                                name: '',
                                alternativeText: '',
                                caption: '',
                                width: 0,
                                height: 0,
                                formats: {} as Formats,
                                hash: '',
                                ext: '',
                                mime: '',
                                size: 0,
                                url: '',
                                provider: '',
                                createdAt: new Date(),
                                updatedAt: new Date(),
                            },
                            thumbnail: {
                                name: '',
                                alternativeText: '',
                                caption: '',
                                width: 0,
                                height: 0,
                                formats: {} as Formats,
                                hash: '',
                                ext: '',
                                mime: '',
                                size: 0,
                                url: '',
                                provider: '',
                                createdAt: new Date(),
                                updatedAt: new Date(),
                            }
                        }
                    }
                }]
            },
            stage_head: {
                az: "",
                en: "",
                ru: "",
                id: 0
            },
            stage_title: {
                az: "",
                en: "",
                ru: "",
                id: 0
            },
            stage_desc: {
                az: "",
                en: "",
                ru: "",
                id: 0
            },
        }
    }
}
export const infoAboutSlice = createSlice({
    name: 'about',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchInfoAboutUs.fulfilled.type]: (state, action: PayloadAction<IAboutUs>) => {
            state.isLoading = false
            state.error = ''
            state.info = action.payload
        },
        [fetchInfoAboutUs.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchInfoAboutUs.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        }
    }
})
export default infoAboutSlice.reducer
