import React, {useEffect, useState} from 'react'
import './index.scss'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import i18n from "i18next";
import FooterCall from "../FooterCall/FooterCall";
import FooterBtnUp from "../FooterBtnUp/FooterBtnUp";

const logo = require('../../assets/logo.svg').default
const fb = require('../../assets/fb.svg').default
const phone = require('../../assets/phone.svg').default
const mail = require('../../assets/mail.svg').default
const inst = require('../../assets/inst.svg').default
const footerImg = require('../../assets/footerImg.jpg')

const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);
    const {t} = useTranslation()
    const email = 'info@riberry.az'
    const mobile = '(+994) 55 220 65 65'

    return (
        <footer>
            <div className="footer-wrap">
                <img height={165} width={1400} className='footer__img' src={footerImg} alt="Photo"/>
                <div className='footer-body'>
                    <div className='footer-body__left'>
                        <p className='footer__title'>{t('Contact us')}</p>
                        <p className='footer-contact'>
                            <img width={24} height={24} src={phone} alt='Photo'/>{' '}
                            <a className='footer-telephone' target="_blank" rel="nofollow" href={`tel:${mobile}`}>
                                {mobile}
                            </a>
                        </p>
                        <p className='footer-contact'>
                            <img width={24} height={24} src={mail} alt='Photo'/>
                            <a className='footer-email' target="_blank" rel="nofollow" href={`mailto:${email}`}>
                                {email}
                            </a>
                        </p>
                        <div className="footer-info">
                            <a
                                className='footer-social'
                                target="_blank" rel="nofollow"
                                href='https://www.facebook.com/Riberry/'
                            >
                                <img height={24} width={24} src={fb} alt='facebook'/>
                            </a>
                            <a
                                className='footer-social'
                                target="_blank" rel="nofollow"
                                href='https://www.instagram.com/riberry_design/'
                            >
                                <img height={24} width={24} src={inst} alt='instagram'/>
                            </a>
                            <a
                                className='footer-social'
                                target="_blank" rel="nofollow"
                                href='https://www.youtube.com/channel/UC5qZX_RxVmIFDxFJ_bylnlA'
                            >
                                <img style={{ marginLeft: 5}} height={24} width={24} src={require('../../assets/youtube-svgrepo.svg').default} alt='youtube'/>
                            </a>
                        </div>
                    </div>
                    <div className='footer-body__right'>
                        <NavLink to={`/${i18n.language}/terms-of-use/`}
                                 className="footer-body__right-terms">{t('termsUse')}</NavLink>
                        <NavLink to={`/${i18n.language}/`}>
                            <img width={198} height={50} src={logo} alt='logo'/>
                        </NavLink>
                    </div>
                </div>

            </div>
            <div className="footer-call-wrapper">
                <div className="footer-wrap-call">
                    <FooterCall/>
                </div>
                <FooterBtnUp/>
            </div>
        </footer>
    )
}

export default Footer
