import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchBlog } from './ActionCreators'
import {IBlogs} from '../../models/IBlogs'
import {photoAttrib} from "../../models/IPhoto";

interface BlogState {
	isLoading: boolean
	error: string
	blog_description: string
	blog: IBlogs
}

interface Formats {
	small: photoAttrib
	large: photoAttrib
	thumbnail: photoAttrib
	medium: photoAttrib
}

// @ts-ignore
const initialState: BlogState = {
	isLoading: false,
	error: '',
	blog_description: '',
	blog: {
		id: 0,
		attributes: {
			type: '',
			blog_date: '',
			blog_description: '',
			blog_img: {
				data: [{
					id: null,
					attributes: {
						name: '',
						width: 0,
						height: 0,
						url: '',
						formats: {
							small: {
								name: '',
								alternativeText: '',
								caption: '',
								width: 0,
								height: 0,
								formats: {} as Formats,
								hash: '',
								ext: '',
								mime: '',
								size: 0,
								url: '',
								provider: '',
								createdAt: new Date(),
								updatedAt: new Date(),
							},
							large: {
								name: '',
								alternativeText: '',
								caption: '',
								width: 0,
								height: 0,
								formats: {} as Formats,
								hash: '',
								ext: '',
								mime: '',
								size: 0,
								url: '',
								provider: '',
								createdAt: new Date(),
								updatedAt: new Date(),
							},
							thumbnail: {
								name: '',
								alternativeText: '',
								caption: '',
								width: 0,
								height: 0,
								formats: {} as Formats,
								hash: '',
								ext: '',
								mime: '',
								size: 0,
								url: '',
								provider: '',
								createdAt: new Date(),
								updatedAt: new Date(),
							}
						}
					}
				}]
			},
			stage_title: {
				az: '',
				en: '',
				ru: '',
				id: 0
			},
			stage_head: {
				az: '',
				en: '',
				ru: '',
				id: 0
			},
			stage_desc: {
				az: '',
				en: '',
				ru: '',
				id: 0
			},
			views: 0,
			raiting_star: 0,
			blog_contents: [{
				id: 0,
				blog_body_az: '',
				blog_body_en: '',
				blog_body_ru: '',
				blog_title_az: '',
				blog_title_ru: '',
				blog_title_en: '',
				blog_contents_image_1: {
					data: [{
						id: null,
						attributes: {
							name: '',
							width: 0,
							height: 0,
							url: '',
							formats: {
								small: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								},
								large: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								},
								thumbnail: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								}
							}
						}
					}]
				},
				blog_contents_image_2: {
					data: [{
						id: null,
						attributes: {
							name: '',
							width: 0,
							height: 0,
							url: '',
							formats: {
								small: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								},
								large: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								},
								thumbnail: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								}
							}
						}
					}]
				},
				blog_contents_image_3: {
					data: [{
						id: null,
						attributes: {
							name: '',
							width: 0,
							height: 0,
							url: '',
							formats: {
								small: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								},
								large: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								},
								thumbnail: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								}
							}
						}
					}]
				}
			}],
			category_article: '',
			recommended_articles: [{
				id: 0,
				recommended_articles_date: '',
                recommended_lang_desc: {
                    az: '',
                    en: '',
                    ru: '',
                    id: 0
                },
				recommended_lang_title: {
                    az: '',
                    en: '',
                    ru: '',
                    id: 0
                },
				recommended_articles_image: {
					data: [{
						id: null,
						attributes: {
							name: '',
							width: 0,
							height: 0,
							url: '',
							formats: {
								small: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								},
								large: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								},
								thumbnail: {
									name: '',
									alternativeText: '',
									caption: '',
									width: 0,
									height: 0,
									formats: {} as Formats,
									hash: '',
									ext: '',
									mime: '',
									size: 0,
									url: '',
									provider: '',
									createdAt: new Date(),
									updatedAt: new Date(),
								}
							}
						}
					}]
				},
			}]
		}
	}
}
export const blogSlice = createSlice({
	name: 'blog',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchBlog.fulfilled.type]: (state, action: PayloadAction<IBlogs>) => {
			state.isLoading = false
			state.error = ''
			state.blog = action.payload
		},
		[fetchBlog.pending.type]: state => {
			state.isLoading = true
		},
		[fetchBlog.rejected.type]: (state, action: PayloadAction<string>) => {
			state.isLoading = false
			state.error = action.payload
		}
	}
})
export default blogSlice.reducer
