import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPhoto } from '../../models/IPhoto'
import { fetchPhotoById, fetchPhotos } from './ActionCreators'

interface PhotoState {
	photosById: IPhoto | null
	isLoading: boolean
	error: string
}

const initialState: PhotoState = {
	photosById: null,
	isLoading: false,
	error: ''
}
export const photoByIdSlice = createSlice({
	name: 'photosById',
	initialState,
	reducers: {},

	extraReducers: {
		[fetchPhotoById.fulfilled.type]: (state, action: PayloadAction<IPhoto>) => {
			state.isLoading = false
			state.error = ''
			state.photosById = action.payload
		},
		[fetchPhotoById.pending.type]: state => {
			state.isLoading = true
		},
		[fetchPhotoById.rejected.type]: (state, action: PayloadAction<string>) => {
			state.isLoading = false
			state.error = action.payload
		}
	}
})
export default photoByIdSlice.reducer
